import * as types from '../mutations-types'

export default {
    namespaced: true,

    state:{
        news: []
    },

    actions:{
        fetchAll({ commit }){
            return axios.get('/news')
            .then((response) => {
                commit(types.STORE_NEWS, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return axios.get(`/news/${id}`)
            .then((response) => {
                return response.data;
            })
        },
    },

    mutations:{
        [types.STORE_NEWS](state, news){
            state.news = news
        }
    },
}