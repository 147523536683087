import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        sectors: []
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/sectors')
                .then((response) => {
                    commit(types.STORE_SECTORS, response.data);
                    return response.data;
                })
        },

        fetchById({ commit }, { id }) {
            return axios.get(`/sectors/${id}`)
                .then((response) => {
                    return response.data;
                })
        }
    },

    mutations: {
        [types.STORE_SECTORS](state, sectors) {
            state.sectors = sectors;
        }
    },

    getters: {
        getSectorById: state => id => state.sectors.find(c => c.id === id)
    }
}