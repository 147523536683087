import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        standards: []
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/standards')
                .then((response) => {
                    commit(types.STORE_STANDARDS, response.data)
                    return response.data
                })
        },

        fetchById({ commit }, { id }) {
            return axios.get(`/standards/${id}`)
                .then((response) => {
                    return response.data
                })
        }
    },

    mutations: {
        [types.STORE_STANDARDS](state, standards) {
            state.standards = standards;
        }
    },
}
