<template>
	<div>
		<div v-if="isValid('line')" class="line-loader"></div>
		<div class="spinner-loader" v-else>
			<div class="spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["line"],

	methods: {
		isValid(prop) {
			return this[prop] || typeof this[prop] === "string" ? true : false;
		},
	},
};
</script>

<style>
.line-loader {
	width: 100%;
	margin: 0 auto;
	padding: 2px !important;
	position: relative;
}
.line-loader::after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 200px;
	height: 4px;
	border-radius: 10px;
	background-color: var(--first-color);
	animation: line-loader 2s cubic-bezier(0.31, 0.14, 0.71, 0.94) 0.3s infinite
		alternate forwards;
}
@keyframes line-loader {
	0% {
		width: 0%;
		right: 0;
	}
	25% {
		width: 10%;
	}
	60% {
		width: 40%;
		right: 50%;
	}
	100% {
		width: 0%;
		right: 100%;
	}
}

.spinner-loader {
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.spinner-loader .spinner-border {
	color: var(--first-color);
	border-width: 5px;
	width: 40px;
	height: 40px;
}
</style>