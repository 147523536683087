import * as type from '../mutations-types'

export default {
    namespaced: true,

    state:{
        testimonials:[]
    },

    actions:{
        fetchAll({ commit }){
            return axios.get('/albums')
            .then((response) => {
                commit(type.STORE_TESTIMONIALS, response.data);
                return response.data;
            })
        }
    },

    mutations:{
        [type.STORE_TESTIMONIALS](state, testimonials){
            state.testimonials = testimonials;
        }
    }
}