import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { top: 0 }
	},
	routes
})

export default router
