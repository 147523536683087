import { createStore } from 'vuex'
import sliderImages from './modules/slider-images'
import standards from './modules/standards'
import sectors from './modules/sectors'
import services from './modules/services'
import news from './modules/news'
import testimonials from './modules/testimonials'
import feedback from './modules/feedback'
import sets from './modules/sets'
import contactUs from './modules/contact-us'
import values from './modules/values'
import search from './modules/search'

export default createStore({
	modules: {
		sliderImages,
		standards,
		sectors,
		services,
		news,
		testimonials,
		feedback,
		sets,
		contactUs,
		values,
		search
	}
})
