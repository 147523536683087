<template>
	<div class="bottom-sheet" v-if="sheet">
		<p class="cookie-text">
			By clicking “Accept all cookies”, you agree that Mieyar can store cookies
			on your device and disclose information in accordance with our Cookie
			Policy.
		</p>
		<button class="accept-btn" @click="acceptCookies()">
			Accept all cookies
		</button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			sheet: true,
		};
	},

	methods: {
		acceptCookies() {
			this.sheet = false;
			localStorage.setItem("atc_cookies_accepted", "yes");
		},
	},

	mounted() {
		if (localStorage.getItem("atc_cookies_accepted") === "yes") {
			this.sheet = false;
		}
	},
};
</script>

<style>
.bottom-sheet {
	display: flex;
	align-items: center;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 1rem 2rem;
	color: white;
	font-family: RobotoBlack;
	color: var(--sec-color);
	background-color: white;
	border-top: 1px solid white;

	opacity: 0;
	animation: bottom-sheet-fade-in 0.8s ease-in-out 2s forwards;
}
.cookie-text {
	font-family: inherit;
	margin: 0;
}
.accept-btn {
	display: block;
	margin: 0;
	margin-left: auto;
	background: var(--sec-color);
	padding: 5px 15px 5px;
	border: none;
	border-radius: 3px;
	font-size: 0.9em;
	color: white;
}
@media screen and (max-width: 1200px) {
	.bottom-sheet {
		flex-direction: column;
	}
	.accept-btn {
		margin: 0 auto;
		margin-top: 1rem;
	}
}
@keyframes bottom-sheet-fade-in {
	from {
		opacity: 0;
		bottom: -50px;
	}
	to {
		opacity: 1;
		bottom: 0;
	}
}
</style>