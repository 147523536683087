<template>
	<ul>
		<i class="fas fa-home" style="color: var(--font-color)"></i>
		<li v-for="link in links" :key="link.name">
			<router-link
				:to="link.active ? {} : link.path"
				:class="link.active ? 'a' : ''"
			>
				{{ link.name }}
			</router-link>
			<div class="arrow" v-if="!link.active"></div>
		</li>
	</ul>
</template>

<script>
export default {
	props: ["links"],
	data() {
		return {
			//   links: [
			//     { name: "Home", path: "/" },
			//     { name: "Events", path: "/events" },
			//     { name: "Product", path: "/product" },
			//   ],
		};
	},
};
</script>

<style scoped>
ul {
	list-style: none;
	padding: 0px !important;
	margin: 0;
}
svg {
	width: 14px !important;
	padding-top: 4px;
}
li {
	display: inline-block;
	padding: 0 5px;
}
li a {
	padding-right: 5px;
	text-decoration: none;
	font-size: 14px;
	color: var(--font-color);
}
.arrow {
	display: inline-block;
	width: 5px;
	height: 5px;
	margin-bottom: 2px;
	transform: rotate(-45deg);
	border-right: 1px solid black;
	border-bottom: 1px solid black;
}
.a {
	color: var(--first-color);
}
@media only screen and (max-width: 992px) {
	* {
		font-size: 18px !important;
	}
	svg {
		width: 20px !important;
		padding: 0;
	}
}
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	li a {
		font-size: 18px;
	}
	.arrow {
		width: 6px;
		height: 6px;
	}
	svg {
		width: 30px !important;
		padding: 0;
	}
}
</style>
