export const STORE_STANDARDS = 'STORE_STANDARDS';

export const STORE_SECTORS = 'STORE_SECTORS';

export const STORE_SERVICES = 'STORE_SERVICES';

export const STORE_NEWS = 'STORE_NEWS';

export const STORE_TESTIMONIALS = 'STORE_TESTIMONIALS';

export const STORE_SLIDER_IMAGES = 'STORE_SLIDER_IMAGES';

export const STORE_SETS = 'STORE_SETS';

export const STORE_CONTACTS = 'STORE_CONTACTS';

export const STORE_VALUES = 'STORE_VALUES';

export const STORE_SEARCH = 'STORE_SEARCH';