<template>
	<div>
		<Header></Header>
		<router-view />
		<Footer></Footer>
		<bottom-sheet />
	</div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import BottomSheet from "./components/BottomSheet";

export default {
	name: "App",
	components: {
		Header: Header,
		Footer: Footer,
		BottomSheet,
	},
};
</script>
<style>
:root {
	--first-color: #3085a1;
	--sec-color: #ddd;
	--sec-color-rgb: 204, 204, 204;
	--third-color: #ff9900;
	--color-white: rgb(233, 236, 241);
	--font-color: #212428;
	--footer-color: #474e56;
}
@font-face {
	font-family: Roboto;
	src: url("./assets/Fonts/Roboto-Regular.ttf");
}
@font-face {
	font-family: RobotoItalic;
	src: url("./assets/Fonts/Roboto-Italic.ttf");
}
@font-face {
	font-family: RobotoBlack;
	src: url("./assets/Fonts/Roboto-Black.ttf");
}
@font-face {
	font-family: RobotoBold;
	src: url("./assets/Fonts/Roboto-Bold.ttf");
}

* {
	margin: 0;
	font-size: 16px;
	box-sizing: border-box;
	font-family: Roboto;
	color: var(--font-color);
}
@media (max-width: 992px) {
	* {
		font-size: 14px;
	}
}
@media (min-width: 1700px) {
	* {
		font-size: 20px;
	}
}
a {
	font-size: inherit;
	text-decoration: none;
}
/* hide recaptcha badge */
.grecaptcha-badge {
	display: none;
}
</style>
