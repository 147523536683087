<template>
	<div class="container" ref="a">
		<div class="main">
			<div class="img">
				<router-link :to="{ name: 'home' }">
					<img src="../assets/CompanyLogo/logo.png" alt="" />
				</router-link>
			</div>
			<div class="fs-list footer-list">
				<div class="fs-title footer-title">
					Services and topics
					<div class="line"></div>
				</div>
				<div class="fs-ul">
					<div class="footer-list">
						<router-link
							v-for="service in featuredServices.slice(0, 10)"
							:key="service.id"
							:to="{ name: 'service', params: { id: service.id } }"
							>{{ service.Title }}</router-link
						>
					</div>
					<!-- <div class="footer-list" v-if="featuredServices.length > 7">
						<router-link
							v-for="service in featuredServices.slice(6, 12)"
							:key="service.id"
							:to="{ name: 'service', params: { id: service.id } }"
							>{{ service.title }}</router-link
						>
						<span v-if="featuredServices.length < 7">Lorem ipsum</span>
					</div> -->
				</div>
			</div>
			<!-- secotrs -->
			<div class="s-list footer-list">
				<div class="s-title footer-title">
					sectors
					<div class="line"></div>
				</div>
				<div class="s-ul">
					<div class="footer-list">
						<router-link
							v-for="sector in sectors.slice(0, 10)"
							:key="sector.id"
							:to="{ name: 'sector', params: { id: sector.id } }"
							>{{ sector.name }}</router-link
						>
					</div>
				</div>
			</div>

			<!-- news -->
			<div class="fs-list footer-list">
				<div class="fs-title footer-title">
					news
					<div class="line"></div>
				</div>
				<div class="fs-ul">
					<div class="footer-list">
						<router-link
							v-for="news in featuredNews.slice(0, 10)"
							:key="news.id"
							:to="{ name: 'news', params: { id: news.id } }"
							>{{ news.Title }}</router-link
						>
					</div>
				</div>
			</div>

			<!-- contact us -->
			<div class="t-list footer-list">
				<div class="t-title footer-title">
					contact us
					<div class="line"></div>
				</div>
				<div class="t-ul">
					<div class="footer-list pe-0">
						<router-link :to="{ name: 'contact-us' }">
							Send request
						</router-link>
						<div>Libya - Tripoli - znata</div>
						<div>00218912009009</div>
						<div>00218913206660</div>
						<div class="social">
							<div><i class="fab fa-facebook-f"></i></div>
							<div>
								<i class="fab fa-linkedin-in"></i>
							</div>
							<div><i class="fab fa-instagram"></i></div>
							<div><i class="fab fa-twitter"></i></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright">
			<div class="c">
				CopyRight @ {{ new Date().getFullYear() }} -- All Rights Reserved
			</div>
			<div>Mieyar</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {},

	computed: {
		featuredServices() {
			return this.services.flat().filter((c) => c.is_featured);
		},
		featuredNews() {
			return this.news.flat().filter((c) => c.is_featured);
		},
		...mapState({
			services: (state) => state.services.services,
			sectors: (state) => state.sectors.sectors,
			news: (state) => state.news.news,
		}),
	},

	mounted() {
		if (!this.news.length) {
			this.$store.dispatch("news/fetchAll").then();
		}
		if (!this.services.length) {
			this.$store.dispatch("services/fetchAll").then();
		}
	},
};
</script>

<style scoped>
.container {
	max-width: 100%;
	margin: 0;
	padding: 0 80px;
	border-top: 3px solid var(--third-color);
	background: #082d42;
}
* {
	color: #ddd;
}
li:hover {
	color: var(--first-color);
}
.img {
	height: 180px;
}
.img a {
	display: block;
	height: 100%;
}
.img img {
	height: 100%;
	filter: brightness(100);
}
.social {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 200px;
	margin: auto;
}
.social div {
	border: 1px solid white;
	border-radius: 5px;
	width: 28px;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 3px;
}
.social div * {
	color: white !important;
}
.social div:hover {
	border: 1px solid var(--first-color);
}
.social div:hover * {
	color: var(--first-color) !important;
}
.main {
	display: flex;
	color: #ccc;
	padding-top: 40px;
	padding-bottom: 30px;
	justify-content: space-between;
}
.main > div {
	display: inline-block;
}
.footer-title {
	width: 100%;
	padding: 10px 0;
	position: relative;
	font-family: RobotoBlack;
	text-transform: uppercase;
	font-size: 1.05rem;
}
.t-list {
	margin-right: 60px;
}
.line {
	background: var(--third-color);
	width: 40px;
	height: 2px;
	position: absolute;
	top: 100%;
}
.fs-ul,
.s-ul,
.t-ul {
	display: flex;
	flex-wrap: wrap;
}
.footer-list,
ul {
	padding: 10px 0;
	padding-right: 20px;
	margin: 0;
	list-style: none;
}
.footer-list,
li {
	line-height: 20px;
	font-size: 1rem;
}
.footer-list a,
.footer-list a:visited,
.footer-list a:link {
	display: block;
	text-decoration: none;
	color: inherit;
	max-width: 250px;
}
.footer-list a,
.footer-list div {
	margin-bottom: 7px;
}
.footer-list a:hover {
	color: var(--first-color);
}

.copyright {
	padding: 3px 0;
	color: #ccc;
	border-top: 1px solid #ccc;
	display: flex;
	justify-content: space-between;
}
.copyright * {
	font-size: 0.9rem;
}
@media only screen and (max-width: 992px) {
	.container {
		padding: 20px 20px 0;
	}
	.main {
		flex-direction: column;
		align-items: center;
	}
	.main > div {
		width: 100%;
	}
	.img {
		height: auto;
		padding-bottom: 20px;
	}
	.img img {
		margin: auto;
		display: block;
		width: 40vw;
	}
	.social {
		justify-content: space-evenly;
	}
	.social div {
		width: 30px;
		height: 30px;
	}
	.fs-ul,
	.s-ul,
	.t-ul {
		flex-wrap: nowrap;
		justify-content: center;
	}
	.fs-ul ul:last-child,
	.s-ul ul:last-child,
	.t-ul ul:last-child {
		padding-right: 0;
	}
	.s-ul {
		flex-wrap: wrap;
	}
	.s-ul ul {
		width: 50%;
		padding-bottom: 0;
	}
	.s-ul ul:nth-child(3) {
		width: 100%;
		padding-top: 0;
	}
	.s-ul ul:nth-child(3) li {
		width: 50%;
		display: inline-block;
	}
	.s-ul ul:nth-child(3) li:last-child {
		display: none;
	}
	.t-ul ul,
	.fs-ul ul {
		width: 50%;
	}
	.main li {
		margin-bottom: 2px;
	}
	.img {
		text-align: center;
	}
	.line {
		left: 50%;
		transform: translateX(-50%);
	}
	.fs-list,
	.s-list {
		padding-right: 0;
	}
	.footer-list {
		text-align: center;
	}
	.footer-list:last-child {
		padding-right: 0;
	}
	.copyright * {
		font-size: 0.8rem;
	}
}
@media (max-width: 500px) {
	.img img {
		width: 80vw;
	}
}
</style>
