<template>
	<div class="btn-body">
		<router-link :to="to" style="text-decoration: none">
			<div>
				{{ text }}
				<img src="../../assets/Icons/double-right-arrows.png" alt="" />
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	props: { text: String, to: { typeof: Object, default: {} } },
};
</script>

<style scoped>
.btn-body {
	padding: 10px;
	font-size: 11px;
	padding: 10px 25px;
	border-radius: 3px;
	font-family: RobotoBlack;
	background: var(--first-color);
}
.btn-body div {
	color: white;
	font-weight: 700;
}
.btn-body img {
	margin-left: 3px;
	height: 7px;
	transition: 200ms;
}
.btn-body:hover {
	box-shadow: 0px 0px 5px #333;
}
.btn-body:hover img {
	transform: translateX(2px) scale(2);
}
@media only screen and (max-width: 992px) {
	.btn-body {
		padding: 8px 20px 8px;
		font-size: 16px;
	}
}
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	.btn-body {
		font-size: 15px;
	}
}
</style>
