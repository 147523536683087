<template>
	<div class="container px-0 mx-0">
		<div class="title" style="padding: 0" ref="header">
			<img src="../../assets/Images/1.jpg" alt="" />
			<div class="t-text">
				<div class="t-mid">Standards</div>
				<div class="line"></div>
				<div class="t-down">
					The point of a standard is to provide a reliable basis for people to
					share the same expectations about a product or service.
				</div>
			</div>
		</div>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'Standards', active: 'true' },
				]"
			></breadcrumbs>
		</div>
		<div class="main">
			<div class="navbar" :class="{ show: showNavbar }" ref="navbar">
				<div class="navbar-toggler" @click="showNavbar = !showNavbar">
					<i :class="showNavbar ? 'fa fa-times' : 'fa fa-list'"></i>
				</div>
				<div class="navbar-title">standards</div>
				<ul class="navbar-list">
					<li
						class="navbar-item"
						v-for="standard in standardsInfo"
						:key="standard.id"
						@click="changeStandards(standard.id)"
						:ref="`navitem${standard.id}`"
					>
						{{ standard.title }}
					</li>
					<li class="navbar-item" @click="scrollToStandards()">
						all standards
					</li>
				</ul>
			</div>
			<div class="contant">
				<div class="mb-2">
					<div class="contant-title">
						Standards and schemes for certidication
					</div>
					<p class="pre-line">
						{{
							`Standards define best practice in many different areas. They’re put together by groups of experts and come in a number of different kinds, from a set of definitions to a series of strict rules. 
				In essence, a standard is an agreed way of doing something. It could be about making a product, managing a process, delivering a service, or supplying materials – standards can cover a huge range of activities undertaken by organizations and used by their customers.
				Standards are the refined wisdom of people with expertise in their subject matter and who know the needs of the organizations they represent – people such as manufacturers, sellers, buyers, customers, trade associations, users, or regulators.
				Our portfolio extends to more than 8700 current standards. They are designed for voluntary use so it’s up to you – you’re not forced to follow a set of rules that make life harder for you, you’re offered ways to do your work better.
				Standards are knowledge. They are powerful tools that can help drive innovation and increase productivity. They can make organizations more successful and people’s everyday lives easier, safer, and healthier.`
						}}
					</p>
				</div>
				<div class="mb-2" v-for="standard in standardsInfo" :key="standard.id">
					<h2
						class="s-title"
						:ref="`title${standard.id}`"
						@click="changeStandards(standard.id)"
					>
						{{ standard.title }}
					</h2>
					<p class="s-paragraph">
						{{ standard.description }}
					</p>
				</div>
			</div>
		</div>
		<div class="contant-title mt-5 pb-0" ref="list" id="standards">
			all standards
		</div>
		<loader v-if="!standards.length" class="loader"></loader>
		<div class="all-standards mt-2 pb-5" v-else>
			<div v-for="standard in standards" :key="standard.id">
				<item-card
					:item="standard"
					:to="{ name: 'standard', params: { id: standard.id } }"
				></item-card>
			</div>
		</div>
	</div>
</template>

<script>
import ItemCard from "../comp/ItemCard.vue";
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import { mapState } from "vuex";
import Loader from "../Loader.vue";

export default {
	components: {
		ItemCard,
		Breadcrumbs,
		Loader,
	},

	computed: {
		...mapState({
			standards: (state) => state.standards.standards,
		}),
	},
	methods: {
		scrollToStandards() {
			window.scrollTo({
				top: document.getElementById("standards").offsetTop - 80,
				behavior: "smooth",
			});
		},
		changeStandards(id) {
			window.scrollTo({
				top:
					this.$refs[`title${id}`][0].offsetTop +
					this.$refs.header.clientHeight -
					10,
				behavior: "smooth",
			});
		},
		initStandardNavbar() {
			const topPartHeight = document.querySelector(".title").clientHeight;
			for (let i = 0; i < this.standardsInfo.length; i++) {
				this.standardsScroll.push(
					this.$refs[`title${i}`][0].offsetTop + topPartHeight
				);
			}
			window.addEventListener("scroll", this.changeStandardsScroll);
		},
		changeStandardsScroll() {
			var x = -1;
			this.standardsScroll.forEach((e, i) => {
				this.$refs[`navitem${i}`][0].classList.remove("select");
				if (e < document.documentElement.scrollTop + 80) {
					x = i;
				}
			});
			this.$refs[`navitem${x}`] &&
				this.$refs[`navitem${x}`][0].classList.add("select");
		},
		toggleNavBarBackground() {
			if (document.documentElement.scrollTop > 400 || window.innerWidth > 992)
				this.$refs.navbar.style.display = "block";
			else this.$refs.navbar.style.display = "none";
		},
	},
	data() {
		return {
			standardsInfo: [
				{
					id: 0,
					title: "Standards",
					description: `Standards define best practice in many different areas. They’re put together by groups of experts and come in a number of different kinds, from a set of definitions to a series of strict rules. 
					In essence, a standard is an agreed way of doing something. It could be about making a product, managing a process, delivering a service, or supplying materials – standards can cover a huge range of activities undertaken by organizations and used by their customers.
					Standards are the refined wisdom of people with expertise in their subject matter and who know the needs of the organizations they represent – people such as manufacturers, sellers, buyers, customers, trade associations, users, or regulators.
					Standards are knowledge. They are powerful tools that can help drive innovation and increase productivity. They can make organizations more successful and people’s everyday lives easier, safer, and healthier.
					The kinds of things that standards do:
					Standards cover a wide range of subjects from construction to nanotechnology, from energy management to health and safety, from cricket balls to goalposts. They can be very specific, such as to a particular type of product, or general such as management practices.
					The point of a standard is to provide a reliable basis for people to share the same expectations about a product or service. This helps to:
					- facilitate trade
					- enhance consumer protection and confidence.
					Organizations might use:
					- a quality management standard to help them work more efficiently and reduce product failures
					- an environmental management standard to help reduce environmental impacts, reduce waste and be more sustainable
					- a health and safety standard to help reduce accidents in the workplace
					- an IT security standard to help keep sensitive information secure
					- a construction standard to help build a house
					- an energy management standard to help cut energy consumption
					- a food safety standard to help prevent food from being contaminated
					- an accessibility standard to help make buildings accessible to disabled users
					- an interoperability standard to ensure that bank and credit cards fit into ATMs and can be used throughout the world.`,
				},
				{
					id: 1,
					title: "How are standards made?",
					description: `A standard is a collective work. Representatives of organizations having an interest and expertise in the subject matter are brought together  a technical committee to shape the standard, with our staff facilitating their development and review.
					Typically, our technical committees comprise representatives of industry bodies, research and testing organizations, local and central government, consumers and standards users.
					We have experience of shaping standards – leading and facilitating the process of reaching consensus among experts. We ensure standards committees are representative, inclusive and accessible and the process is precise and transparent. We apply specific principles for drafting standards that help to ensure that our standards are authoritative and widely respected.`,
				},
				{
					id: 2,
					title: "Different types of standard",
					description: `Standards all have the same basic purpose of setting out agreed principles or criteria so that their users can make reliable assumptions about a particular product, service or practice.
					However, they can vary in two major respects:
					- the type of agreement
					- the number of people, organizations, or countries who were involved in making the agreement.
					In some standards, the type of agreement essentially are equal to advice and guidance; others are much more prescriptive and set out absolute requirements that have to be met if a user wishes to make a claim of compliance with the standard.
					Different subject areas and different user groups have needs for differing forms and levels of standardization, and MIEYAR tries to meet all these needs.`,
				},
				{
					id: 3,
					title: "Standards and regulation",
					description: `If you comply with a MIEYAR Standard, then it’s pretty clear that you take your responsibilities seriously as an organization, and indeed compliance is often taken as evidence of due diligence. It certainly speaks much about your attitudes to doing things properly.
					However, standards aren’t the same as regulations and following a standard doesn’t guarantee that you’re within the relevant laws. In fact, standards rarely cite the law as legislation could change within the lifetime of the standard.`,
				},
				{
					id: 4,
					title: "How standards are used in law",
					description: `The government often shape standards when putting together legislation or guidance documents. Standards are used to establish the technical detail, allowing the legislation to concentrate on long term policy objectives – for example product safety, or environmental protection.
					In a case like this, compliance with the standard will often mean you’re compliant with the relevant legislation, although there are usually ways of being compliant with legislation without using a standard.
					It’s also worth noting that MIEYAR is not authorized to check and supervise every organization’s claim to be compliant with British Standards – that would usually be a matter for a trading standards unit at a local authority. A false claim of compliance is likely to put you on the wrong side of the law.`,
				},
				{
					id: 5,
					title: "How standards support emerging technologies",
					description: `Businesses looking to commercialize emerging technologies are part of a developing business ‘ecosystem’, and the stronger they can take advantage of the networks within the ecosystem, the faster the market for their products will grow.  However, these complex ecosystems can give rise to obstacles that get in the way of successful innovation.
					Standards are key to removing these obstacles as they can help an emerging technology ecosystem rally round the issues to promote successful commercialization of new products. They can lay down the ‘rules of the game’ for markets looking to develop and adopt emerging technologies: for example they can help define interoperability so that one product or service can work with another made by a different organization, or they can put down rules which reassure consumers about safety and reliability, particularly for technologies which are perceived to be risky.
					Standards can also organize the development of new technologies and related products, though processes and systems which are known to work.`,
				},
				{
					id: 6,
					title: "Benefits of using standards",
					description: `Standards are a tried and tested way to work more efficiently and effectively. They help organizations to improve their performance, reduce their risk and help them be more sustainable. 
					- improve performance
					Standards give you the knowledge  you need to fine-tune your organization so it performs at its best, day in, day out – at every level. The results are tangible growth and increased profits.
					Using our standards can help you improve the quality of your services, manage projects so they meet deadlines and stay within budget, strengthen the resilience of your IT systems, certify your products so they attract more customers, manage your assets better, and build successful collaborative relationships with other organizations.
					Working with standards can help you to depend on every member of staff, from the boardroom to reception, to be driving the performance of the business at all times. And once you’ve met the demands of the standard, certification allows you to promote it, making you stand out from the crowd.

					The kinds of areas where you can improve performance
					- Quality management – make sure your services are as good as you’d like them to be.
					- Product certification – check your products are safe and reliable.
					- Customer satisfaction – don’t just hope, make sure you’re serving your customers.
					- IT service management – you rely on them, so make certain your IT services are truly reliable.
					- Project management – learn how to get things done on time and on budget.
					- Reducing risk 
					Serious accidents, IT security breaches, fire, floods, loss of suppliers, extreme weather – any of these can cripple a business, and many never recover. Using standards systematically prepares you in advance so threats like these, and many others you may never have considered, are far less likely to hit you, or can even be turned into opportunities
					If an incident does occur, your down time and all the other costs of recovery will be significantly reduced.
					But tackling risk can do much more for your business.
					By understanding the risks you face and then acting to mitigate them, you become a tougher, more resilient business, able also to respond to and build upon new opportunities. If a problem does arise, or if you and your competitors face industry-wide challenges, you are better equipped to survive the initial problem and then rapidly recover, gaining market share as you do.
					And that’s not all. By tackling your risks you’ll enhance your reputation, give your customers greater confidence, win new business and very likely reduce your costs. Above all you’ll understand more accurately how your business works and what its success depends on.
					What sorts of risks can MIEYAR standards help you tackle?
					- Information security – is your confidential data properly protected?
					- Health and safety – do you have proper processes to manage health and safety in your workplace?
					- Data protection – are you sure you’re holding customer details securely?
					- Supply chain security – how badly could you be hit if someone in your supply chain went down?
					- Business continuity – do you have a system in place to help you recover from any of a range of incidents that disrupt your ability to operate?
					- Food safety – can you ensure food safety along the food chain?
					- Sustainable 
					Using standards can help you save energy, save money, and save the environment.
					Sustainability has never been more important. But at the same time, people are more cynical than ever about ‘green’ marketing claims. They want to see proof of your commitment to tackling environmental and social challenges.
					Implementing our standards can help you to demonstrate your credentials to customers, employees and stakeholders, and showcase your commitment to sustainable development. 
					Today, we can help you deliver substantial environmental, economic and social benefits, whatever your size, sector or geographical location. We can help you identify and manage the impact of your business on the environment and community, understand relevant legislation, and most importantly use resources more efficiently by implementing powerful policies across your organization. This not only helps the environment, it makes a real difference to your bottom line.`,
				},
				{
					id: 7,
					title: "Who uses standards",
					description: `Organizations of all sizes use standards to compete more effectively, from small businesses to multinationals and governments.
					- Small businesses
					If you’re a small business, you already know that you have to work harder to prove yourself against larger organizations. That’s where our standards come in. Whether you want to monitor and improve product quality, strengthen your IT system security, implement a complaint management system, vet potential employees, or give customers extra peace of mind, using our standards can help.
					Implementing standards inspires confidence in your business and sets you apart from the competition. And, crucially for your bottom line, it can drive down costs, boost productivity and improve profits.
					It can also help retain existing custom and open doors to new markets. Indeed, some contracts and supply chains are only open to standards-compliant businesses. organizations.
					Standards can:
					- Help you allocate resources more efficiently.
					- Get your product to market quicker.
					- Reduce your risks from IT failure.
					Help you compete effectively with larger organizations.
					-national and multinational businesses
					When leading businesses use standards, they save themselves the time and effort of reinventing the wheel. They gain the finest knowledge from the world’s leading experts. That means they don’t need to waste their efforts in the wrong place.
					Meanwhile they are freed to concentrate their efforts on what they do best – making brilliant new products, generating powerful creative ideas, delivering great service.
					For businesses that operate globally, applying international standards is one of the best ways to demonstrate quality, compatibility and consistency, and helps create a common language upon which to trade.
					Standards are particularly important for businesses that are in the public eye, where reputation can suffer catastrophic damage as a result of an environmental issue or a problem that harms customers.
					We also ask individual large businesses and trade associations to get involved in producing standards. They help us create standards that take full account of technical and commercial needs, and reduce bureaucracy and barriers to trade. This is how we can be sure that standards actually help businesses rather than holding them back.
					Standards offer the knowledge organizations need to succeed in the face of intense competition.
					-Government
					Using standards benefits government organizations in many ways, from organizing their own processes to helping build alternative approaches to regulation.

					Government is keen to reduce bureaucracy for both the private and public sectors. A key way to do this is to have regulators and their inspectors concentrate their efforts on those organizations who present the highest risk – rather than putting the burden on everyone, as has often been the case in the past.

					This is where standards come in. Not to replace regulations, but to complement them. Standards are shaped by best practice, so if an organization is using them, it’s probably being well managed. This makes it easier for regulators to decide where to focus their attention. Standards are the foundation on which a risk-based approach to regulation can be built.

					We work closely in many areas. Its representatives participate in our technical committees along with those from business, academia and consumer groups. Together, we make sure that products, processes, new technologies and services incorporate the knowledge and needs of all interested parties.

					Using our standards also helps the government’s own processes become more efficient, for example eliminating waste and achieving maximum value for money in its procurement processes. 

					Finally, standards help government departments encourage the growth of groundbreaking new products and industries – by providing dependable knowledge and standardization so that new technologies can work seamlessly with existing technologies.`,
				},
			],
			standardsScroll: [],
			showNavbar: false,
			a: true,
		};
	},
	watch: {
		"$route.hash"() {
			if (this.$route.hash) {
				setTimeout(() => {
					this.scrollToStandards();
				}, 1);
			}
		},
	},
	mounted() {
		this.initStandardNavbar();
		if (this.$route.hash) {
			setTimeout(() => {
				this.scrollToStandards();
			}, 1);
		}
	},
	beforeUnmount() {
		window.removeEventListener("scroll", this.changeStandardsScroll);
	},
};
</script>

<style scoped>
.loader {
	width: 100%;
}
.navbar-toggler {
	display: none;
}
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div {
	padding: 0 80px;
}
.main {
	position: relative;
	display: grid;
	grid-template-areas: "contant navbar";
}
.navbar {
	display: none;
	margin-top: 50px;
	position: sticky;
	top: 100px;
	right: 40px;
	display: block;
	float: right;
	grid-area: navbar;
	height: max-content;
}
.navbar-title {
	color: var(--first-color);
	font-size: 2rem;
	font-family: RobotoBlack;
	text-transform: uppercase;
}
.navbar-list {
	list-style: none;
	padding: 0;
}
.navbar-item {
	border-left: 2px solid var(--first-color);
	font-size: 1rem;
	max-width: 300px;
	padding: 10px 20px;
	cursor: pointer;
}
@media (max-width: 576px) {
	.navbar-item {
		max-width: 250px;
	}
}
.navbar-item.select {
	background: var(--first-color);
	color: white;
	border-left: 3px solid var(--third-color);
}
.contant {
	grid-area: contant;
	padding-right: 50px;
}
.title {
	width: 100%;
	position: relative;
}
.title img {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}
.contant-title,
.s-title {
	font-size: 32px;
	color: var(--first-color);
	font-family: RobotoBlack;
	margin: 30px 0 10px;
	border-bottom: 3px solid transparent;
	position: relative;
	padding-inline-end: 5px;
	cursor: pointer;
	text-transform: capitalize;
}
.s-paragraph {
	text-align: justify;
}
.contant-title {
	cursor: auto;
}
.s-title::before {
	content: "#";
	visibility: hidden;
	position: absolute;
	left: -20px;
}
.s-title:hover {
	border-bottom: 3px solid var(--first-color);
}

.s-title:hover::before {
	visibility: visible;
}
.column {
	padding: 10px;
}

.pre-line {
	white-space: pre-line;
}
.all-standards {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 30px;
	row-gap: 20px;
}
@media (max-width: 992px) {
	.all-standards {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media (max-width: 768px) {
	.all-standards {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media (max-width: 576px) {
	.all-standards {
		column-gap: 10px;
		padding: 0 !important;
		/* grid-template-columns: repeat(1, 1fr); */
	}
}
@media only screen and (max-width: 992px) {
	.container > div {
		padding: 20px 25px 40px;
		margin: 0;
	}
	.s-title {
		padding-bottom: 0 !important;
	}
	.column {
		padding: 10px 0;
	}
	.breadcrumbs {
		padding: 10px 25px !important;
	}
	.t-text {
		width: 70%;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
	.contant {
		padding: 0;
	}
	.contant-title,
	.s-title {
		width: unset;
	}
	.navbar {
		display: none;
		position: fixed;
		right: 0;
		background: var(--color-white);
		box-shadow: 0 0 10px black;
		top: 48px;
		transform: translateX(101%);
		z-index: 5;
		border-left: 1px solid #aaa;
		padding: 20px;
		transition: 300ms;
	}
	.navbar.show {
		transform: translateX(0%);
	}
	.navbar-toggler {
		display: none;
		position: absolute;
		top: 0;
		left: -1px;
		transform: translateX(-100%);
		padding: 15px 23px;
		background: var(--color-white);
		box-shadow: -3px -2px 3px gray;
		border-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		border-top-right-radius: unset;
		border-bottom-right-radius: unset;
		border-right: 2px solid #ccc;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>