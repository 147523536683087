import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        contacts: []
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/contact-us')
                .then((response) => {
                    commit(types.STORE_CONTACTS, response.data);
                    return response.data;
                })
        },
    },

    mutations: {
        [types.STORE_CONTACTS](state, contacts) {
            state.contacts = contacts;
        }
    },
}