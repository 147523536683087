<template>
	<div class="container px-0 mx-0">
		<div class="title" style="padding: 0">
			<div v-if="loading" class="loading-cover"></div>
			<img v-else class="head-image" :src="oneNews.images[0]" />
			<div class="t-text">
				<h1 class="t-mid">{{ oneNews.Title }}</h1>
				<div class="line" v-if="!loading"></div>
			</div>
			<loader line v-if="loading" />
		</div>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'News', path: '/all-news' },
					{ name: oneNews.Title, active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" />
		<div v-else class="main">
			<div class="main-news">
				<img
					:src="oneNews.images[1]"
					alt=""
					class="image"
					:class="{ 'd-none': !oneNews.images[1] }"
				/>
				<div
					v-html="oneNews.Content"
					id="news-content"
					class="container pt-4 pb-5"
				></div>
				<div class="contant-title mb-3 more-news">More News</div>
				<div class="row more-news mb-4">
					<div
						class="my-col"
						v-for="onenews in news
							.filter((e) => e.id !== oneNews.id)
							.slice(0, 3)"
						:key="onenews.id"
					>
						<news-card :data="onenews"></news-card>
					</div>
				</div>
			</div>
			<div class="top-standards">
				<h2 class="contant-title">standards</h2>
				<div v-for="standard in standards.slice(0, 4)" :key="standard.id">
					<item-card
						:item="standard"
						:to="{ name: 'standard', params: { id: standard.id } }"
					></item-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import Loader from "../Loader";
import { mapState } from "vuex";
import ItemCard from "../comp/ItemCard.vue";
import NewsCard from "../comp/NewsCard.vue";
export default {
	components: {
		Breadcrumbs,
		Loader,
		ItemCard,
		NewsCard,
	},

	data() {
		return {
			oneNews: {},
			loading: true,
		};
	},
	computed: {
		...mapState({
			standards: (state) => state.standards.standards,
			news: (state) => state.news.news,
		}),
	},
	watch: {
		$route(newRoute) {
			if (newRoute.params.id) {
				this.fetchNews();
			}
		},
	},

	methods: {
		fetchNews() {
			this.loading = true;
			this.$store
				.dispatch("news/fetchById", { id: this.$route.params.id })
				.then((data) => {
					this.oneNews = data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	mounted() {
		this.fetchNews();
	},
};
</script>

<style scoped>
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div:not(:first-child) {
	padding: 0 80px 50px;
}
@media (max-width: 992px) {
	.container > div:not(:first-child) {
		padding: 20px 10px 40px;
	}
}
.title {
	width: 100%;
	position: relative;
}
.title .head-image,
.title .loading-cover {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.title .loading-cover {
	background-color: var(--sec-color);
	filter: grayscale(0.6);
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}
.main {
	display: flex;
	padding-top: 50px !important;
}
@media (max-width: 768px) {
	.main {
		flex-direction: column;
	}
}
.main-news {
	padding-right: 15px;
}
.main-news > img,
.main-news > .news-content {
	padding-right: 50px;
}
.image {
	width: 100%;
	border-radius: 5px;
}
.top-standards {
	position: sticky;
	top: 120px;
	min-width: 250px;
	padding-left: 15px;
	border-left: 1px solid var(--first-color);
	height: max-content;
}

@media (min-width: 1500px) {
	.top-standards {
		min-width: 350px;
	}
}
.contant-title {
	font-size: 2.2rem;
	color: var(--first-color);
	font-family: RobotoBold;
	text-transform: capitalize;
	margin-bottom: 0.4rem;
}
@media (max-width: 768px) {
	.top-standards {
		display: grid;
		grid-template-columns: 1fr 1fr;
		border: none;
		padding: 0 15px;
		row-gap: 10px;
		column-gap: 20px;
	}
	.top-standards > *:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}

@media only screen and (max-width: 992px) {
	.breadcrumbs {
		padding: 10px 20px !important;
	}
	.t-mid {
		font-size: 32px;
	}
	.t-down {
		width: auto;
	}
	.main-news > img,
	.main-news > .news-content {
		padding-right: 0;
	}
}
@media (max-width: 768px) {
	.main-news {
		padding-right: 0;
	}
	.more-news {
		padding: 0 15px;
	}
}
.my-col {
	width: 33%;
}
@media (max-width: 1200px) {
	.my-col {
		width: 50%;
	}
	.my-col:last-child {
		display: none;
	}
}
@media (max-width: 600px) {
	.my-col {
		width: 100%;
	}
	.my-col:last-child {
		display: block;
	}
}
@media (min-width: 600px) {
	.more-news > div {
		min-height: 300px;
	}
}
</style>

<style>
#news-content * {
	font-family: RobotoBlack;
	font-size: 1rem;
}
#news-content h1,
#news-content h2,
#news-content h3,
#news-content h4,
#news-content h5,
#news-content h6 {
	font-family: RobotoBlack;
	color: var(--first-color);
	font-size: 34px;
	margin: 20px 0 10px;
}
#news-content ul {
	padding-left: 1rem;
}
#news-content img {
	width: 60%;
}

@media only screen and (max-width: 992px) {
	#news-content {
		padding: 0;
	}
	#news-content > * {
		margin: 10px 0;
	}
	#news-content h1,
	#news-content h2,
	#news-content h3,
	#news-content h4,
	#news-content h5,
	#news-content h6 {
		font-size: 30px;
		line-height: 35px;
		margin-bottom: 20px;
		word-break: break-all;
	}
}
#news-content * {
	width: auto !important;
	background: transparent !important;
}
</style>