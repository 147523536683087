import Home from '../components/Home/Home'
import Service from '../components/services/Service'
import AllServices from '../components/services/AllServices'
import Sector from '../components/sectors/Sector'
import AllSectors from '../components/sectors/AllSectors'
import ContactUs from '../components/ContactUs/ContactUs'
import Standard from '../components/Standard/Standard'
import AllStandards from '../components/Standard/AllStandards'
import News from '../components/news/News'
import AllNews from '../components/news/AllNews'
import AboutUs from '../components/AboutUs/AboutUs'
import Search from '../components/search/Search.vue'

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/service/:id',
        name: 'service',
        component: Service
    },
    {
        path: '/all-services',
        name: 'all-services',
        component: AllServices
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/standard/:id',
        name: 'standard',
        component: Standard
    },
    {
        path: '/all-standards',
        name: 'all-standards',
        component: AllStandards
    },
    {
        path: '/sector/:id',
        name: 'sector',
        component: Sector
    },
    {
        path: '/all-sectors',
        name: 'all-sectors',
        component: AllSectors
    },
    {
        path: '/news/:id',
        name: 'news',
        component: News
    },
    {
        path: '/all-news',
        name: 'all-news',
        component: AllNews
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },

]