import * as types from '../mutations-types'

export default {
    namespaced: true,

    state:{
        services: []
    },

    actions:{
        fetchAll({ commit }){
            return axios.get('/services')
            .then((response) => {
                commit(types.STORE_SERVICES, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return axios.get(`/services/${id}`)
            .then((response) => {
                return response.data;
            })
        },
    },

    mutations:{
        [types.STORE_SERVICES](state, services){
            state.services = services
        }
    },
}