<template>
	<div class="container mx-0 px-0">
		<div class="title" style="padding: 0">
			<img src="../../assets/Images/1.jpg" alt="" />
			<div class="t-text">
				<div class="t-mid">Search</div>
				<div class="line"></div>
				<div class="t-down"></div>
			</div>
		</div>
		<loader v-if="loading" />
		<div class="main" v-else>
			<section class="standards">
				<h2 class="main-title mb-2 mt-4">Standards:</h2>
				<div class="row">
					<item-card
						class="col-lg-3 col-md-4 col-6"
						:item="standard"
						:to="{ name: 'standard', params: { id: standard.id } }"
						v-for="standard in standards"
						:key="standard.id"
					></item-card>
					<p
						class="col-12 text-uppercase text-center fs-2 text-black-50 my-5"
						v-if="!standards.length"
					>
						there is no standards
					</p>
				</div>
			</section>
			<section class="services" v-if="!$route.query.type">
				<h2 class="main-title mb-2 mt-5">services:</h2>
				<div class="row">
					<service-card
						v-for="service in services"
						:key="service.id"
						:text="service.Title"
						:image="service.primary_image"
						:to="{ name: 'service', params: { id: service.id } }"
						class="col-lg-3 col-md-4 col-sm-6 col-12 d-md-block"
					></service-card>
					<p
						class="col-12 text-uppercase text-center fs-2 text-black-50 my-5"
						v-if="!services.length"
					>
						there is no services
					</p>
				</div>
			</section>
			<section class="news" v-if="!$route.query.type">
				<h2 class="main-title mb-2 mt-5">news:</h2>
				<div class="row">
					<news-card
						v-for="news in news"
						:key="news.id"
						:data="news"
						class="col-lg-3 col-md-4 col-sm-6 col-12 d-md-block mb-3"
					></news-card>
					<p
						class="col-12 text-uppercase text-center fs-2 text-black-50 my-5"
						v-if="!Object.keys(news).length"
					>
						there is no news
					</p>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import ItemCard from "../comp/ItemCard.vue";
import ServiceCard from "../comp/ServiceCard.vue";
import NewsCard from "../comp/NewsCard.vue";
import Loader from "../Loader.vue";
import { mapState } from "vuex";

export default {
	components: {
		ItemCard,
		ServiceCard,
		NewsCard,
		Loader,
	},
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			standards: (state) => state.search.standards,
			services: (state) => state.search.services,
			news: (state) => state.search.news,
		}),
	},
	methods: {
		search() {
			this.loading = true;
			this.$store.dispatch("search/search", this.$route.query.text).then(() => {
				this.loading = false;
				console.log(Object.keys(this.news).length);
			});
		},
	},
	watch: {
		$route() {
			this.search();
		},
	},
	mounted() {
		this.search();
	},
};
</script>

<style scoped>
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div {
	padding: 0 60px;
}
.container > div:not(:first-child) {
	padding: 40px 70px 40px;
}
@media (max-width: 992px) {
	.container > div:not(:first-child) {
		padding: 40px 20px 80px;
	}
}
@media (min-width: 1500px) {
	.container > div:not(:first-child) {
		padding: 20px 150px 40px;
	}
}
.title {
	width: 100%;
	position: relative;
}
.title img {
	width: 100%;
	height: 280px;
	object-fit: cover;
	filter: contrast(70%);
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.title .head-image,
.title .loading-cover {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.main {
}
.main-title {
	color: var(--first-color);
	font-family: RobotoBlack;
	text-transform: capitalize;
}
.news a {
	height: 350px;
}
@media (max-width: 600px) {
	.news a {
		height: auto;
	}
}
@media only screen and (max-width: 992px) {
	.container > div {
		padding: 20px 20px 40px;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
}
</style>