<template>
	<div class="container px-0 mx-0">
		<div class="title">
			<div v-if="loading" class="loading-cover" />
			<img
				v-else-if="standard.images[0]"
				class="head-image"
				:src="standard.images[0]"
			/>
			<img v-else class="head-image" src="../../assets/Images/1.jpg" />
			<div class="t-text" v-if="!loading">
				<div class="t-up">{{ standard.code }}</div>
				<h1 class="t-mid">{{ standard.name }}</h1>
				<div class="line" v-if="!loading"></div>
				<div class="t-down">
					{{
						standard.summary.length > 100
							? standard.summary.slice(0, 100) + "..."
							: standard.summary
					}}
				</div>
			</div>
		</div>

		<loader line v-if="loading" />

		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'Standard', path: '/all-standards' },
					{ name: standard.name, active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" />
		<div v-else class="contant pt-4 pb-5">
			<div class="summary my-4">
				{{ standard.summary }}
			</div>
			<div
				id="standard-description"
				class="left"
				v-html="standard.description"
			></div>

			<div class="right">
				<div class="right-image mt-4">
					<img
						:src="standard.images[1]"
						alt=""
						:class="{ 'd-none': !standard.images[1] }"
					/>
				</div>
				<div class="right-sticky">
					<div class="r-title">Request a quote</div>
					<p>
						Find out how mush ISO 9001 certification coul cost your business
					</p>
					<div class="d-flex right-btns">
						<my-btn
							:text="'Request a Quote'"
							:to="{ name: 'contact-us', query: { standardId: standard.id } }"
							class="btn"
						></my-btn>
						<my-btn
							:text="'Make an enquiry'"
							:to="{ name: 'contact-us', query: { standardId: standard.id } }"
							class="btn"
						></my-btn>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import MyBtn from "../comp/MyBtn.vue";
import Loader from "../Loader";

export default {
	data() {
		return {
			loading: true,

			standard: {},
		};
	},

	watch: {
		$route(newRoute) {
			if (newRoute.params.id) {
				this.fetchStandard();
			}
		},
	},

	components: {
		Breadcrumbs,
		Loader,
		MyBtn,
	},

	methods: {
		fetchStandard() {
			this.loading = true;
			this.$store
				.dispatch("standards/fetchById", { id: this.$route.params.id })
				.then((data) => {
					this.standard = data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	mounted() {
		this.fetchStandard();
	},
};
</script>

<style scoped>
@media (min-width: 992px) {
	.summary {
		display: none;
	}
}
@media (max-width: 992px) {
	.t-down {
		display: none;
	}
	.title {
		font-size: 1.4rem;
		font-family: RobotoBold;
		color: var(--first-color);
		text-decoration: underline;
	}
}
* {
	color: var(--font-color);
}
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > .breadcrumbs,
.container > .contant {
	padding: 0 80px 50px;
}
.title {
	width: 100%;
	position: relative;
}
.title .head-image,
.title .loading-cover {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.title .loading-cover {
	background-color: var(--sec-color);
	filter: grayscale(0.6);
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 90px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}

.contant {
	display: flex;
}

.right {
	flex: 1;
}
@media (max-width: 992px) and (min-width: 700px) {
	.right {
		display: flex;
	}
	.right-sticky {
		margin-bottom: auto;
		margin-left: 10px;
	}
}
@media (max-width: 400px) {
	.right-btns {
		flex-direction: column;
	}
}
.right-image {
	width: 100%;
}
.right-image > img {
	width: 100%;
}
.right-sticky {
	position: sticky;
	top: 90px;
	height: max-content;
}
.right * {
	font-family: RobotoBlack;
}
.right .r-title {
	color: var(--first-color);
	font-size: 25px;
	margin: 30px 0 10px;
}
.right p {
	font-size: 1rem;
}
.right .btn {
	width: max-content;
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 8px;
}

@media only screen and (max-width: 992px) {
	.contant {
		flex-direction: column;
		padding: 0 20px 50px !important;
	}
	.container > div:not(:first-child) {
		padding: 20px 10px 40px;
	}
	.breadcrumbs {
		padding: 10px 20px !important;
	}
	.t-mid {
		font-size: 32px;
	}
	.t-down {
		width: auto;
	}
}
</style>

<style>
#standard-description.left {
	flex: 2;
	padding-right: 70px;
}
#standard-description.left * {
	font-family: Roboto;
	font-size: 1rem;
}
#standard-description.left > * {
	margin: 5px 0;
}
#standard-description.left h1,
#standard-description.left h2,
#standard-description.left h3,
#standard-description.left h4,
#standard-description.left h5,
#standard-description.left h6 {
	font-family: RobotoBlack;
	color: var(--first-color);
	font-size: 34px;
	margin: 20px 0 10px;
}
#standard-description.left ul {
	padding-left: 14px;
}
#standard-description.left img {
	width: 60%;
}

@media only screen and (max-width: 992px) {
	#standard-description.left {
		padding: 0;
	}
	#standard-description.left > * {
		margin: 10px 0;
	}
	#standard-description.left h1,
	#standard-description.left h2,
	#standard-description.left h3,
	#standard-description.left h4,
	#standard-description.left h5,
	#standard-description.left h6 {
		font-size: 30px;
		line-height: 35px;
		margin-bottom: 20px;
		word-break: break-all;
	}
}
#standard-description * {
	width: auto !important;
	background: transparent !important;
}
</style>