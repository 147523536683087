<template>
	<div class="container px-0 mx-0">
		<div class="title" style="padding: 0">
			<img src="../../assets/Images/1.jpg" alt="" />
			<div class="t-text">
				<div class="t-mid">Contact Us</div>
				<div class="line"></div>
				<div class="t-down">
					The moment you decide to improve your business, our client managers
					step up to assist you.
				</div>
			</div>
		</div>
		<Loader line v-if="loading"></Loader>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'Contact Us', active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<div class="main row justify-content-center mx-0">
			<div class="left col-xl-8 col-sm-10 col-12">
				<form @submit.prevent="submit" ref="form" novalidate>
					<div class="f-title">let our team help you</div>
					<p>Please fill the form below with the following information.</p>
					<div class="row" v-if="sent">
						<div class="col-12">
							<p class="sent-notice">
								Your request was successfully sent! We will come back to you
								shortly.
							</p>
							<div style="width: max-content; margin: 0 auto">
								<atc-btn
									:to="{ name: 'all-standards' }"
									text="Browse more standards"
								></atc-btn>
							</div>
						</div>
					</div>
					<template v-else>
						<div class="row">
							<!-- name -->
							<div class="col-md-4 col-sm-6 ps-0 pe-4">
								<input
									v-model="name"
									type="name"
									placeholder="Full Name"
									class="contact-field"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								/>
							</div>

							<!-- email -->
							<div class="col-md-4 col-sm-6 ps-0 pe-4">
								<input
									v-model="email"
									type="email"
									placeholder="Email"
									class="contact-field"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								/>
							</div>

							<!-- Phone Number -->
							<div class="col-md-4 col-sm-6 px-0">
								<input
									v-model="phoneNumber"
									placeholder="Phone Number"
									class="contact-field"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								/>
							</div>

							<!-- Company name -->
							<div class="col-md-4 col-sm-6 ps-0 pe-4">
								<input
									v-model="companyName"
									placeholder="Company name"
									class="contact-field"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								/>
							</div>

							<!-- Job Title -->
							<div class="col-md-4 col-sm-6 ps-0 pe-4">
								<input
									v-model="jobTitle"
									placeholder="Job Title"
									class="contact-field"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								/>
							</div>

							<!-- Country -->
							<div class="col-md-4 col-sm-6 px-0">
								<select
									class="dropmenu contact-field"
									v-model="country"
									placeholder="Country"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								>
									<option value disabled selected hidden>Country</option>
									<option
										v-for="country in countries"
										:key="country.code"
										:value="country.name + ' - ' + country.code"
									>
										{{ country.name + " (" + country.code + ")" }}
									</option>
								</select>
							</div>

							<!-- Sector -->
							<div class="col-sm-6 ps-0 pe-4">
								<select
									class="dropmenu contact-field"
									v-model="sector"
									placeholder="Sector"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								>
									<option value disabled selected hidden>Sector</option>
									<option
										v-for="sector in sectors"
										:key="sector.id"
										:value="sector.name"
									>
										{{ sector.name }}
									</option>
								</select>
							</div>

							<!-- standard -->
							<div class="col-sm-6 px-0">
								<select
									class="dropmenu contact-field"
									v-model="standard"
									placeholder="Standard"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								>
									<option value disabled selected hidden>Standard</option>
									<option
										v-for="standard in standards"
										:key="standard.id"
										:value="standard.name"
									>
										{{ standard.name + " - " + standard.code }}
									</option>
								</select>
							</div>

							<!-- service -->
							<div class="col-sm-12 px-0">
								<select
									class="dropmenu contact-field"
									v-model="service"
									placeholder="Service"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									:disabled="loading"
									required
								>
									<option value disabled selected hidden>Service</option>
									<option
										v-for="service in services"
										:key="service.id"
										:value="service.Title"
									>
										{{ service.Title }}
									</option>
								</select>
							</div>

							<!-- message -->
							<div class="col-sm-12 px-0">
								<textarea
									v-model="body"
									type="text"
									id="massage"
									class="textarea-field contact-field"
									:class="{ 'is-not-valid': isNotValid, disabled: loading }"
									placeholder="Message"
									:disabled="loading"
									required
								></textarea>
							</div>
						</div>
						<button class="submit-btn" v-show="!loading" type="submit">
							SEND
						</button>
					</template>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import countries from "../../countries";
import Loader from "../../components/Loader";
import { mapState } from "vuex";
export default {
	components: {
		Breadcrumbs,
		Loader,
	},

	data() {
		return {
			name: null,
			email: null,
			phoneNumber: null,
			companyName: null,
			jobTitle: null,
			country: "",
			sector: "",
			standard: "",
			service: "",
			body: null,

			countries,

			isNotValid: false,
			loading: true,
			sent: false,
		};
	},

	methods: {
		submit() {
			if (this.$refs.form.checkValidity()) {
				window.recaptcha(() => {
					this.loading = true;
					this.isNotValid = false;
					this.$store
						.dispatch("feedback/create", {
							name: this.name,
							email: this.email,
							type: this.type,
							body: this.body,
							phone: this.phoneNumber,
							company: this.companyName,
							job: this.jobTitle,
							country: this.country,
							sector: this.sector,
							standard: this.standard,
							service: this.service,
						})
						.then(() => {
							this.name = null;
							this.email = null;
							this.body = null;
							this.phone = null;
							this.company = null;
							this.job = null;
							this.country = null;
							this.sector = null;
							this.standard = null;
							this.service = null;
							this.sent = true;
						})
						.finally(() => {
							this.loading = false;
						});
				});
			} else {
				this.isNotValid = true;
			}
		},
	},

	computed: {
		...mapState({
			sectors: (state) => state.sectors.sectors,
			standards: (state) => state.standards.standards,
			services: (state) => state.services.services,
		}),
	},

	mounted() {
		if (this.$route.query.standardId) {
			// Promise.all([
			this.$store
				.dispatch("standards/fetchById", { id: this.$route.query.standardId })
				.then((data) => {
					this.body = `I would like to request a quote for the "${data.name}, ${data.code}" standard.\nBest regards.`;
					this.standard = data.name;
				})
				.finally(() => {
					// this.$store.dispatch("services/fetchAll"),
					// ]).finally(() => {
					this.loading = false;
				});
			// });
		} else {
			// this.$store.dispatch("services/fetchAll").finally(() => {
			// 	this.loading = false;
			// });
			this.loading = false;
		}
	},
};
</script>

<style scoped>
.sent-notice {
	text-align: center;
	font-size: 16px;
}
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > .title,
.container > .breadcrumbs,
.container > .main {
	padding: 0 80px;
}
.title {
	width: 100%;
	position: relative;
}
.title img {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}

.main {
	padding-top: 30px;
}
.left {
	padding: 20px;
	padding-left: 0;
}
form {
	width: 100%;
}
form * {
	margin: 4px 0;
	font-size: 14px;
	color: grey;
}
.f-title {
	font-size: 30px;
	color: var(--first-color);
	font-family: RobotoBlack;
	text-transform: capitalize;
}
.dropmenu {
	background: white;
}
.dropmenu option {
	background: white;
	padding: 10px 0;
	color: var(--font-color);
}
.textarea-field {
	word-break: break-word;
	resize: none;
	height: 120px;
}

.contact-field {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 5px 10px;
	width: 100%;
}

.contact-field:focus {
	outline: none;
	border: 1px solid var(--first-color);
}

.submit-btn {
	display: block;
	margin: 0;
	margin-left: auto;
	width: 110px;
	background: var(--first-color);
	padding: 10px;
	font-size: 14px;
	padding: 5px 15px 5px;
	border: none;
	border-radius: 3px;
	font-family: RobotoBlack;
	color: white;
	font-weight: 700;
}

.disabled {
	background: rgba(255, 255, 255, 0.6);
}

.btn-send {
	background: var(--orange-color);
	width: max-content;
	padding: 6px 40px;
	border-radius: 30px;
	color: white;
	border: none;
}
.right {
	padding: 30px;
	padding-top: 70px;
}
.contact {
	border-bottom: 2px solid var(--borders-color);
}
.contact * {
	font-family: RobotoBlack;
}
.contact div {
	padding: 10px 0;
	font-size: 14px;
	color: var(--font-color);
}

.contact img {
	height: 14px;
	margin-right: 10px;
}
hr {
	width: 80px;
	margin: 20px 0;
}
.social {
}
.social svg {
	margin-right: 7px;
	border: 1px solid var(--font-color);
	border-radius: 50%;
	padding: 3px;
	height: 22px;
	width: 22px;
}
@media only screen and (max-width: 992px) {
	.container > .title,
	.container > .breadcrumbs,
	.container > .main {
		padding: 20px 25px 40px;
		margin: 0;
	}
	.breadcrumbs {
		padding: 10px 25px !important;
	}
	.t-text {
		width: 70%;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
	.left {
		padding: 0;
	}
}
@media only screen and (max-width: 992px) {
	form {
		padding: 5px 0;
	}
	form * {
		font-size: 14px;
	}
	.btn-send {
		padding: 7px 45px;
	}
	.name,
	.email {
		width: 100%;
		margin: 0;
	}
	.email,
	.massage,
	.type,
	.name {
		margin: 7px 0;
	}
	.right {
		padding: 0;
	}
	.contact div {
		font-size: 18px;
	}
	.contact img {
		height: 22px;
	}
	.social svg {
		width: 30px;
		height: 30px;
		padding: 5px;
	}
}

.is-not-valid:invalid {
	border-color: #e62c2c;
	animation: invalid-shake 0.3s ease 0.4s forwards;
}
@keyframes invalid-shake {
	0% {
		transform: translateX(0px);
	}
	25% {
		transform: translateX(-4px);
	}
	50% {
		transform: translateX(4px);
	}
	75% {
		transform: translateX(-4px);
	}
	100% {
		transform: translateX(0px);
	}
}
</style>
