<template>
	<div class="container px-0 mx-0" style="padding-top: 33px; overflow: hidden">
		<div
			id="myCarousel"
			class="carousel slide"
			data-bs-ride="carousel"
			data-bs-interval="true"
			style="padding: 0; background: #aaa"
		>
			<div class="carousel-indicators">
				<button
					v-for="(image, index) in images"
					:key="index"
					type="button"
					data-bs-target="#myCarousel"
					:data-bs-slide-to="index"
					:aria-label="'Slide' + index"
				></button>
			</div>
			<div class="carousel-inner">
				<!-- loading -->
				<div v-if="loading" class="carousel-loading"></div>

				<!-- carousel items -->
				<div
					v-show="!loading"
					class="carousel-item"
					data-bs-interval="4000"
					v-for="image in images"
					:key="image.id"
				>
					<img :src="image.image" alt="Inspiring Trust" />
					<div class="carousel-text" v-html="image.description"></div>
				</div>
			</div>

			<button
				class="carousel-control-prev"
				type="button"
				data-bs-target="#myCarousel"
				data-bs-slide="prev"
			></button>
			<button
				class="carousel-control-next"
				type="button"
				data-bs-target="#myCarousel"
				data-bs-slide="next"
			></button>
		</div>
		<div class="can-help row flex-row-reverse">
			<div class="right col-md-6 col-12">
				<div class="img">
					<img src="../../assets/Images/1.jpg" alt="" />
				</div>
			</div>
			<div class="col-md-6 col-12">
				<div class="ch-title title">
					how Mieyar can help
					<div class="title-line left-align"></div>
				</div>
				<div class="left">
					<p>
						We offer the full spectrum of services to help organizations work
						better. Everything from creating standards of excellence to training
						your people to work in more effective ways, assessing how you’re
						doing, and helping you perform even better in future. Very few
						others do this, and none have been doing it as long as we have.
						<br />
						We can combine any of our products and services to create a package
						that’s tailored to your business. This removes the complexity and
						unnecessary cost of getting you where you want to be – whatever your
						starting point.
					</p>
					<div class="ch-list">
						<div class="">
							<router-link
								:to="{ name: 'service', params: { id: service.id } }"
								v-for="service in featuredServices.slice(0, 3)"
								:key="service.id"
								class="d-block"
							>
								{{ service.Title }}
							</router-link>
						</div>
					</div>
					<div class="ch-btn">
						<my-btn
							:to="{ name: 'about-us' }"
							text="See More About Mieyar"
						></my-btn>
					</div>
				</div>
			</div>

			<div class=""></div>
		</div>

		<div class="featured" ref="featured">
			<div class="f-title title">
				Sectors and Standards
				<div class="title-line"></div>
			</div>
			<div v-if="!loading" class="f-cate-container">
				<div class="f-cata-c">
					<div class="f-cate" ref="cate">
						<div class="f-standard-active-back"></div>
						<div
							class="f-standard"
							:class="{ 'f-standard-active': 0 === selectedTab }"
							@click="
								selectedTab = 0;
								setId = null;
								changeStandards();
							"
						>
							All
							<div class="f-standard-arrow"></div>
						</div>
						<div
							class="f-standard"
							:class="{ 'f-standard-active': index + 1 === selectedTab }"
							v-for="(set, index) in sets"
							:key="set.id"
							@click="
								selectedTab = index + 1;
								setId = set.id;
								changeStandards();
							"
						>
							{{ set.name }}
							<div class="f-standard-arrow"></div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!loading" class="f-main">
				<item-card
					v-for="standard in featuredStandards"
					:key="standard"
					:to="{ name: 'standard', params: { id: standard.id } }"
					:item="standard"
					class="standard-card"
				></item-card>
				<div
					v-if="featuredStandards.length === 0"
					class="text-center fs-4 fw-lighter"
				>
					No featured standards for this set
				</div>
			</div>

			<loader line class="mx-auto" style="width: 240px" v-if="loading" />
		</div>

		<div class="services">
			<div class="s-title title">
				services and topics
				<div class="title-line"></div>
			</div>
			<div class="row" v-if="!loading">
				<service-card
					v-for="(service, i) in services.slice(0, 4)"
					:key="service.id"
					:text="service.Title"
					:image="service.primary_image"
					:to="{ name: 'service', params: { id: service.id } }"
					class="col-lg-3 col-md-4 col-sm-6 col-12 d-md-block"
					:class="{ 'd-lg-block d-md-none d-sm-block d-none': i === 3 }"
				>
				</service-card>
				<div
					v-if="servicesNEW.length === 0"
					class="text-center fs-4 fw-lighter"
				>
					No Services To show
				</div>
			</div>
			<loader line class="mx-auto" style="width: 240px" v-if="loading" />
		</div>
		<div class="global" ref="global">
			<div class="g-title title col-12">
				latest news
				<div class="title-line"></div>
			</div>
			<div class="g-main mx-auto mt-3">
				<div
					class="global-item"
					v-for="(news, i) in news.slice(0, 5)"
					:key="news.id"
					:first="i === 0"
					:style="{ 'grid-area': 'area' + (i + 1) }"
				>
					<news-card :data="news" :first="i === 0"></news-card>
				</div>
				<!-- <div class="global-item" style="grid-area: area1">
					<news-card :first="true"></news-card>
				</div>
				<div class="global-item" style="grid-area: area2">
					<news-card></news-card>
				</div>
				<div class="global-item" style="grid-area: area3">
					<news-card></news-card>
				</div>
				<div class="global-item d-md-block d-none" style="grid-area: area4">
					<news-card></news-card>
				</div>
				<div class="global-item d-md-block d-none" style="grid-area: area5">
					<news-card></news-card>
				</div> -->
			</div>
			<div class="global-image">
				<img src="../../assets/Images/map.png" alt="" />
				<img src="../../assets/Images/map.png" alt="" />
			</div>
		</div>
		<div class="testimonial">
			<div class="t-title title col-12" style="color: white">
				testimonial
				<div class="title-line"></div>
			</div>
			<testimonial></testimonial>
		</div>
	</div>
</template>

<script>
import MyBtn from "../comp/MyBtn.vue";
import ServiceCard from "../comp/ServiceCard.vue";
import Loader from "../Loader";
import { useWindowSize } from "vue-window-size";
import { mapState } from "vuex";
import ItemCard from "../comp/ItemCard.vue";
import NewsCard from "../comp/NewsCard.vue";
import Testimonial from "../comp/Testimonial.vue";

export default {
	components: {
		MyBtn,
		ServiceCard,
		Loader,
		ItemCard,
		NewsCard,
		Testimonial,
	},
	data() {
		return {
			loading: true,

			selectedTab: -1,
			setId: null,

			newsBegine: 0,
			newsEnd: 5,
			newsIndex: 0,
			newsArr: [],
		};
	},

	methods: {
		// first standards enter
		standardsEnter() {
			var featured = this.$refs.featured;
			if (featured !== null) {
				const rect = featured.getBoundingClientRect();
				if (rect.bottom <= window.innerHeight - 100) {
					this.changeStandards();
					window.removeEventListener("scroll", this.standardsEnter);
				}
			}
		},

		// standards entry
		changeStandards() {
			setTimeout(() => {
				document.querySelectorAll(".standard-card").forEach((e) => {
					e.classList.add("standard-enter");
				});
			}, 200);
		},

		// change the postion of selected standard
		standardsSwitch() {
			setTimeout(() => {
				const back = document.querySelector(".f-standard-active-back");
				const theActive = document.querySelector(".f-standard-active");
				const container = document.querySelector(".featured");
				const containerPadding = window
					.getComputedStyle(container)
					?.getPropertyValue("padding-left");
				const containerMargin = window
					.getComputedStyle(container)
					?.getPropertyValue("margin-left");

				back.style.width = theActive.getBoundingClientRect().width + "px";
				back.style.left =
					theActive.getBoundingClientRect().left -
					parseInt(containerPadding) -
					parseInt(containerMargin) +
					"px";
			}, 1);
		},
		fetch() {
			if (
				!this.images.length ||
				!this.services.length ||
				!this.sets.length ||
				!this.standards.length
			) {
				Promise.all([
					this.$store.dispatch("sliderImages/fetchAll"),
					this.$store.dispatch("services/fetchAll"),
					this.$store.dispatch("sets/fetchAll"),
					this.$store.dispatch("standards/fetchAll"),
				])
					.then(() => {
						this.loading = false;
					})
					.finally(() => {
						this.initPage();
					});
			} else {
				this.loading = false;
				setTimeout(() => {
					this.initPage();
				}, 1);
			}
		},
		initPage() {
			this.selectedTab = 0;
			document.querySelector(".carousel-item").classList.add("active");
			document
				.querySelector(".carousel-indicators button")
				.classList.add("active");

			window.addEventListener("scroll", this.standardsEnter);
			window.addEventListener("resize", this.standardsSwitch);

			this.newsArr = this.news.slice(0, 5);
			// setInterval(() => {
			// 	this.changeNews();
			// }, 7000);
		},
		changeNews() {
			var x = [];
			if (!this.news.length) return;
			x = [];
			this.newsIndex++;
			for (let i = 0, j = this.newsIndex; i < 5; i++, j++) {
				if (j > this.news.length - 1) j = 0;
				x.push(this.news[j]);
			}
			if (this.newsIndex > this.news.length - 1) this.newsIndex = 0;
			this.newsArr = x;
		},
	},
	computed: {
		mobile() {
			return this.windowWidth > 992;
		},

		servicesNEW() {
			return this.mobile
				? this.services.slice(0, 4)
				: this.services.slice(0, 2);
		},

		featuredStandards() {
			var filteredStandards = this.standards;

			if (this.selectedTab === 0 || this.selectedTab === -1) {
				return filteredStandards.slice(0, 8);
			} else {
				return filteredStandards
					.filter((c) => c.categories_id.find((id) => id === this.setId))
					.slice(0, 8);
			}
		},
		featuredServices() {
			return this.services.filter((c) => c.is_featured);
		},
		...mapState({
			images: (state) => state.sliderImages.images,
			standards: (state) => state.standards.standards,
			sectors: (state) => state.sectors.sectors,
			services: (state) => state.services.services,
			sets: (state) => state.sets.sets,
			news: (state) => state.news.news,
		}),
	},
	watch: {
		selectedTab() {
			this.standardsSwitch();
		},
	},
	setup() {
		const { width, height } = useWindowSize();
		return {
			windowWidth: width,
			windowHeight: height,
		};
	},

	mounted() {
		this.fetch();
	},
	beforeUnmount() {
		window.removeEventListener("scroll", this.standardsEnter);
		window.removeEventListener("resize", this.standardsSwitch);
	},
};
</script>

<style scoped>
.standard-card:nth-child(even),
.standard-card:nth-child(odd) {
	transition: 0.5s;
	opacity: 0;
}
.standard-card:nth-child(odd) {
	transform: translateY(-35px);
}
.standard-card:nth-child(even) {
	transform: translateY(35px);
}
.standard-enter {
	transform: translateY(0px) !important;
	opacity: 1 !important;
}
.container {
	max-width: 100% !important;
}
.container > div:not(:first-child) {
	padding: 40px 70px 40px;
}
@media (max-width: 992px) {
	.container > div:not(:first-child) {
		padding: 40px 20px 80px;
	}
}
@media (min-width: 1500px) {
	.container > div:not(:first-child) {
		padding: 20px 150px 40px;
	}
}
.title {
	text-align: center;
	font-size: 2rem;
	font-family: RobotoBold;
	line-height: 2rem;
	padding: 20px 0;
	text-transform: uppercase;
	color: black;
}
.can-help > .left * {
	font-size: 1.2rem;
}

.carousel-inner {
	height: 400px;
}
.carousel-loading {
	height: 100%;
	animation: carousel-loading 1.5s ease-in-out infinite;
	background-color: #e7e3e4;
	background-image: linear-gradient(
		120deg,
		transparent 45%,
		#f7f7f7 50%,
		transparent 55%
	);
	background-size: 400%;
	background-repeat: no-repeat;
}
@keyframes carousel-loading {
	0% {
		background-size: 0%;
		background-color: #e7e3e4;
	}
	50% {
		background-color: #c2c2c2;
		background-size: 400%;
	}
	100% {
		background-color: #e7e3e4;
	}
}
.carousel-item {
	background: linear-gradient(to bottom, #333 20%, #0000);
}
.carousel-item img {
	width: 100%;
	height: 400px;
	filter: contrast(55%);
	object-fit: cover;
	position: relative;
}
.carousel-indicators {
	margin-bottom: 40px;
}
.carousel-indicators button {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	border: 1px solid #d5cbc9;
}
.carousel-indicators .active {
	background: #0000;
}
.carousel-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: RobotoBlack;
	font-size: 3rem;
	color: white;
	max-width: 35%;
	text-align: center;
	line-height: 1.2;
}
.carousel-text span {
	font-size: inherit;
}
.carousel-text span {
	font-family: RobotoBlack;
	color: var(--first-color);
}
.can-help {
	color: #222;
	background: var(--color-white);
	font-size: 1rem;
	position: relative;
	padding-top: 50px !important;
}
.ch-title {
	text-align: left;
}
.title-line {
	background: var(--third-color);
	width: 150px;
	height: 2px;
	margin: 10px auto 0;
}
.left-align {
	margin-left: 0;
}
.left {
}
@media (min-width: 1500px) {
	.left {
		left: 150px;
	}
}
.left > * {
}
.left p {
	font-size: 1.1rem;
	margin: 0;
	padding-right: 30px;
	text-align: justify;
}
.ch-list {
	padding: 20px 0;
}
.ch-list a,
.ch-list a:visited,
.ch-list a:link {
	text-decoration: none;
	color: inherit;
	font-size: 1rem;
	width: max-content;
	position: relative;
}
.ch-list a::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 1.5px;
	background: var(--first-color);
	right: 0%;
	bottom: 4px;
}
.ch-list a:hover {
	color: var(--first-color);
}
.ch-list a:hover::after {
	display: none;
}
.ch-btn {
	width: max-content;
}
.right {
}
.right .img {
	margin-left: auto;
	border-radius: 3px;
	margin-left: auto;
	width: 100%;
	height: 600px;
	position: relative;
	z-index: 0;
}
.right .img::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: -15px;
	right: -15px;
	border: 3px solid var(--first-color);
	z-index: -1;
}
@media (max-width: 768px) {
	.right .img {
		height: 400px;
	}
}
@media (max-width: 576px) {
	.right .img {
		height: 300px;
	}
}
.img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.featured {
	padding: 20px 45px 50px !important;
	background: var(--color-white);
	color: #575f6b;
}
.f-title {
}

.f-cate {
	display: flex;
	justify-content: space-evenly;
	padding: 20px 100px;
	position: relative;
}
@media (max-width: 1200px) {
	.f-cate {
		padding: 20px;
	}
}
.f-standard {
	border: 1px solid black;
	border-radius: 3px;
	padding: 10px 15px;
	position: relative;
	text-transform: capitalize;
	cursor: pointer;
	font-family: RobotoBlack;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.f-standard-active {
	background: var(--first-color);
	border: 1px solid var(--first-color);
	color: white;
	transition-delay: 200ms;
}
.f-standard-active-back {
	position: absolute;
	top: 20px;
	bottom: 20px;
	background: var(--first-color);
	transition: 400ms cubic-bezier(0.18, 0.89, 0.32, 1.07);
	border-radius: 3px;
}
@media (max-width: 992px) {
	.f-standard-active-back {
		display: none;
	}
}
.f-standard-active .f-standard-arrow {
	visibility: visible;
	bottom: -6px;
	transition: 300ms;
	transition-delay: 400ms;
}
.f-standard-arrow {
	visibility: hidden;
	border: 7px solid var(--first-color);
	transform: rotate(45deg);
	position: absolute;
	bottom: 6px;
	right: 40%;
}
.f-main {
	margin-top: 1rem;
	display: grid;
	grid-template-rows: min-content;
	grid-template-columns: repeat(auto-fit, 280px);

	grid-auto-rows: min-content;
	grid-auto-columns: repeat(auto-fit, 280px);

	row-gap: 20px;
	column-gap: 10px;

	justify-content: center;
	align-items: center;
}
.global {
	position: relative;
	color: white;
	overflow: hidden;
	background: rgba(var(--sec-color-rgb), 0.6);
	background: var(--color-white);
	padding-bottom: 80px !important;
}

.global-item {
	transition: 500ms ease-out;
}
.global-item.show {
	transform: scale(1);
	opacity: 1;
}
.global-item[first="true"] {
	animation: move 300ms ease-out;
}
@media (max-width: 768px) {
	.global-item:last-child,
	.global-item:nth-last-child(2) {
		display: none;
	}
}
@media (max-width: 600px) {
	.global-item[first="true"] {
		animation: moveDown 300ms;
		height: 250px;
	}
}
@keyframes move {
	0% {
		scale: 0.5;
		transform: translate(100%, -50%);
	}
	70% {
		scale: 0.85;
		transform: translate(0, 0);
	}
	100% {
		scale: 1;
		transform: translate(0, 0);
	}
}
@keyframes moveDown {
	0% {
		scale: 0.5;
		transform: translate(0, 100%);
	}
	70% {
		scale: 0.85;
		transform: translate(0, 0);
	}
	100% {
		scale: 1;
		transform: translate(0, 0);
	}
}
.global-image {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	-o-object-fit: contain;
	object-fit: contain;
	right: 0px;
	width: 200%;
	animation-name: globalAnimation;
	animation-duration: 15s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	display: flex;
}
.global-image img {
	width: 50%;
	object-fit: cover;
}
@media (max-width: 992px) {
	.global-image {
		width: 400%;
		animation-name: globalAnimationMed;
		animation-duration: 30s;
	}
}
@media (max-width: 576px) {
	.global-image {
		width: 800%;
		animation-name: globalAnimationSmall;
		animation-duration: 60s;
	}
}
.g-main {
	min-height: 600px;
	display: grid;
	grid-template-areas: "area1  area1 area2 area3" "area1 area1 area4 area5";
	row-gap: 10px;
	column-gap: 10px;
}
@media (max-width: 992px) {
	.g-main {
		min-height: 500px;
	}
}
@media (max-width: 768px) {
	.g-main {
		min-height: 500px;
		grid-template-areas: "area1  area1 area2" "area1 area1 area3";
	}
}
@media (max-width: 600px) {
	.g-main {
		min-height: 0;
		display: block;
	}
}
.g-main * {
	font-family: RobotoBlack;
}
.g-main > div {
}
.services {
	color: #575f6b;
	background: var(--color-white);
}
.s-title {
	margin-bottom: 20px;
}
.testimonial {
	position: relative;
	background: linear-gradient(#3186a2b0, #3186a2b0),
		url("../../assets/Images/testimonial.jpg");
	color: white;
	background-size: cover;
	background-position: center;
}

@keyframes featured {
	from {
		transform: scale(0.4);
	}
	to {
		transform: scale(1);
	}
}
@keyframes globalAnimation {
	from {
		left: 0;
	}
	to {
		left: -100%;
	}
}
@keyframes globalAnimationMed {
	from {
		left: 0;
	}
	to {
		left: -200%;
	}
}
@keyframes globalAnimationSmall {
	from {
		left: 0;
	}
	to {
		left: -400%;
	}
}
@media only screen and (max-width: 992px) {
	.carousel-text {
		width: 80%;
		max-width: none;
		font-size: 2.5rem;
	}
	.container > div {
		padding: 20px 10px 40px;
	}
	.title {
		font-size: 2rem;
	}
	.back-border {
		margin: 0 10% 50px 20%;
	}
	.featured {
		padding: 20px 20px 40px !important;
	}
	.f-main {
		grid-template-columns: repeat(2, 1fr);
		margin-top: 20px;
	}
	.f-cate-container {
		position: relative;
		height: 50px;
		width: 100%;
		margin: auto;
		align-items: center;
	}
	.f-cata-c {
		margin: auto;
		overflow: auto;
		position: relative;
		height: 100%;
	}
	.f-cate {
		position: absolute;
		padding: 0;
		transition: 100ms;
		left: 0;
		align-items: flex-start;
	}
	.f-standard {
		border: 0;
		padding: 0;
		margin: 0 10px;
		width: max-content;
		left: 0;
		text-align: center;
		font-size: 0.9rem;
	}
	.f-standard-active {
		background: inherit;
		border: 0;
		color: var(--first-color);
	}
	.f-standard-arrow {
		display: none !important;
	}

	.f-card-name {
		white-space: pre-wrap;
		font-size: 22px;
		line-height: 32px;
	}
	.f-card-stand {
		font-size: 20px;
	}
	.g-name {
		font-size: 23px;
	}
}
@media (max-width: 568px) {
	.f-main {
		grid-template-columns: repeat(1, 1fr);
	}
}
@media only screen and (max-width: 400px) {
	.g-main {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		justify-items: center;
		width: 100%;
		font-size: 35px;
	}
	.g-main > div:nth-of-type(3) {
		grid-column: 1/-1;
	}
}
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	.title {
		font-size: 2.4rem;
	}
	.carousel-text {
		font-size: 3.4rem;
	}
	.left p {
		font-size: 1.25rem;
	}
	.ch-list a {
		font-size: 1.2rem !important;
	}
	.can-help > .left * {
		font-size: 1.3rem;
	}
	.g-main {
		font-size: 30px;
	}
	.g-name {
		font-size: 19px;
	}
}
</style>
<style>
.carousel-text > * {
	font-family: RobotoBlack;
	font-size: 3rem;
	color: white;
}
@media only screen and (max-width: 992px) {
	.carousel-text > * {
		font-size: 2.5rem;
	}
}
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	.carousel-text > * {
		font-size: 3.4rem;
	}
}
</style>