<template>
	<div class="container mx-0 px-0">
		<div class="title" style="padding: 0">
			<div v-if="loading" class="loading-cover" />
			<img v-else src="../../assets/Images/1.jpg" alt="" />
			<div class="t-text">
				<div class="t-mid">About</div>
				<div class="line"></div>
				<div class="t-down"></div>
			</div>
			<loader line v-if="loading" />
		</div>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'About Us', active: 'true' },
				]"
			></breadcrumbs>
		</div>
		<loader v-if="loading" />
		<div class="top" v-if="!loading">
			<div class="head">
				<div class="h-top">About</div>
				<div class="h-mieyar">Mieyar</div>
			</div>
			<div class="para">
				<p>
					Our purpose is to inspire trust for a more resilient world. Our
					solutions and services improve performance and support the United
					Nations Sustainable Development Goals. At MIEYAR, our mission is to
					share knowledge, innovation and best practice to help people and
					organizations make excellence a habit.
				</p>
				<p>
					We have been challenging mediocrity and complacency to help embed
					excellence into the way people and products work. That means showing
					businesses how to improve performance, reduce risk and achieve
					sustainable growth.
				</p>
			</div>
		</div>
		<div class="bottom" v-if="!loading">
			<div class="menu-container" ref="container">
				<div class="menu" ref="menu">
					<div
						class="menu-item"
						v-for="(value, index) in values"
						:key="index"
						@click="changeTheText(value.id)"
						:ref="`${value.id}item`"
					>
						<img :src="value.image" alt="" class="menu-img" />
						<div class="menu-text">{{ value.Title }}</div>
					</div>
				</div>
			</div>
			<div class="pre-para pre-para-fade-in" ref="PrePara">
				<div class="pp-title">{{ para.title }}</div>
				<div v-html="para.text" class="paragraph-about"></div>
			</div>
		</div>
		<div class="services pb-5 mt-4" v-if="!loading">
			<div class="pp-title text-capitalize">services</div>
			<div class="row">
				<service-card
					class="col-lg-3 col-md-4 col-sm-6 col-12"
					v-for="(service, i) in services
						.filter((e) => e.is_featured)
						.slice(0, 4)"
					:key="service.id"
					:text="service.Title"
					:image="service.primary_image"
					:class="{ 'd-lg-block d-md-none d-sm-block d-none': i === 3 }"
					:to="{ name: 'service', params: { id: service.id } }"
				></service-card>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import ServiceCard from "../comp/ServiceCard.vue";
import { mapState } from "vuex";
import Loader from "../Loader.vue";
export default {
	components: {
		Breadcrumbs,
		ServiceCard,
		Loader,
	},
	data() {
		return {
			aboutUsMenu: [
				{
					name: "Our People",
					image: require("@/assets/Icons/people.png"),
					text: `Our strength is built on the experience, skills and sheer enthusiasm of our tutors, client managers and senior business leaders. And they all understand how to make excellence a habit.\n
						Business excellence begins with people. And the easiest, most effective way to achieve it is to work with those who’ve already embedded excellence in other organizations. \n
						We have decades of experience working with all types of businesses. So our people understand the challenges that your business could be facing. And they have the skills to recognize where excellence can be achieved and transferred to your employees.\n
						We understand how people work, the habits they form, and how they can improve. That’s why we hire the best and train them constantly so they can train and support you – and help you make excellence a habit in your 
						organization.\n`,
				},
				{
					name: "Our Client",
					image: require("@/assets/Icons/clients.png"),
					text: `The moment you decide to improve your business, our client managers step up to assist you. They work to understand your unique goals. And they do their very best to get you where you want to be, whatever your starting point. What’s more your client manager stays with you from application to certification and beyond, to make sure you get the most from your investment.`,
				},
				{
					name: "Our Partners",
					image: require("@/assets/Icons/partners.png"),
					text: `We have many varied partners. These include international standards bodies, such as International Standards Organization (ISO), and external consultants who help our clients and professional affiliations.\n
						Each of our partners has the same authority, knowledge, and expertise as we do. And we believe in the services they provide to support us in raising standards and making excellence a habit. They include independent consultants who can help you implement standards, laboratories who work with us to test products, international standards bodies and industry associations.`,
				},
				{
					name: "Our Memebers",
					image: require("@/assets/Icons/members.png"),
					text: `We work with members who help us shape best practice codes and standards. And our subscribing members benefit from this work with their commitment to achieving standards of excellence.\n
					There are many ways to get involved with our work around standards. You can join our global community and work with us to set and raise standards. You can also sign up to benefit from using these standards at discounted prices. That’s because we have committee members and subscribing members – and both are incredibly valuable to our business.`,
				},
				{
					name: "Governance",
					image: require("@/assets/Icons/governance.png"),
					text: `We’re transparent about the way we run our business and we follow best practice when it comes to corporate governance. Our board is made up of predominantly non-executive directors who bring wide experience and independence.\n
						The Board of MIEYAR Group is committed to the highest standards of corporate governance which it considers fundamental to the business success.\n
						MIEYAR focus on governance begins with the Board, which has a majority of non-executive directors and is able to draw on the experience of individuals of recognized status from many areas of business. The Board is supported by formal Audit, Remuneration and Nominations Committees, on which the Non-executive Directors are the primary participants, as well as by the Standards Policy and Strategy Committee and the Sustainability Committee.\n
						Underpinning the frameworks of internal control and financial management and their related monitoring and compliance systems is the MIEYAR Code of Business Ethics, which sets the ethical values and high standards of integrity that we insist are upheld in every aspect of the way we do business.`,
				},
				{
					name: "Social Responsibility",
					image: require("@/assets/Icons/social.png"),
					text: `Being socially responsible is about being accountable for the impacts of our decisions and activities on society and the environment through transparent and ethical behaviour.`,
				},
				{
					name: "Our History",
					image: require("@/assets/Icons/history.png"),
					text: `We were there at the birth of the standards movement and have helped shape it ever since, driving businesses globally to perform to the best of their ability.\n
						MIEYAR has played a leading role in developing a new generation of standards to help organizations become better governed and more responsible such as anti-bribery, organizational governance and asset management. MIEYAR has also increased collaboration with experts in new fields such as smart cities, nanotechnologies, cell therapy and Building Information Modelling (BIM).\n
						MIEYAR now delivers a comprehensive business services portfolio to clients, helping them raise their performance and enhance their competitiveness worldwide.`,
				},
				// {
				// 	name: "Our Accreditation",
				// 	image: require("@/assets/Icons/accreditation.png"),
				// 	text: `We have been assessed against internationally recognized standards and operate to the highest levels of quality and service. We are accredited by 20 local and international bodies.\n
				// 	We are among the most respected and reputable management systems certification bodies in the world and are accredited by around 20 local and international bodies including:\n
				// 	- Emirates International Accreditation Centre (EIAC)\n
				// 	- Conditions for the Use of EIAC Accreditation Symbol and ILAC MRA/ IAF MLA Marks\n
				// 	- American National Standards Institute - American Society for Quality National Accreditation Board LLC (ANAB)\n
				// 	- The China National Accreditation Board (CNAB)\n
				// 	- Raad voor Accreditatie (RvA) in the Netherlands\n
				// 	- United Kingdom Accreditation Service (UKAS)\n
				// 	To enable us to provide a cost-effective service, we regularly review our accreditation schemes. We have decided to provide accreditation worldwide with ANSI-ASQ National Accreditation Board (ANAB), supported by local well-known and credible accreditation as appropriate to our customer requirements and the local market needs.`,
				// },
			],
			para: { title: String, text: String, image: String },
			x: 0,
			loading: true,
		};
	},
	methods: {
		changeTheText(index) {
			this.para.text = this.values.find((e) => e.id == index).Content;
			this.para.title = this.values.find((e) => e.id == index).Title;

			// adding fadein animation
			this.$refs.PrePara.classList.remove("pre-para-fade-in");
			setTimeout(() => {
				this.$refs.PrePara.classList.add("pre-para-fade-in");
			}, 200);

			// changing selected tab
			this.values.forEach((e) => {
				this.$refs[`${e.id}item`][0].classList.remove("item-selected");
			});
			this.$refs[`${index}item`][0].classList.add("item-selected");

			// scroll to pre-para
			window.scrollTo({
				top: this.$refs.container.offsetTop - 93,
				behavior: "smooth",
			});
		},
	},
	watch: {
		"$route.query.id"() {
			if (this.$route.name === "about-us")
				setTimeout(() => {
					this.changeTheText(this.$route.query.id);
				}, 1);
		},
	},
	computed: {
		...mapState({
			services: (state) => state.services.services,
			values: (state) => state.values.values,
		}),
	},
	mounted() {
		var interval = setInterval(() => {
			if (this.values.length) {
				clearInterval(interval);
				this.loading = false;

				this.para.text = this.values[0].Content;
				this.para.title = this.values[0].Title;
				this.para.image = this.values[0].image;
				setTimeout(() => {
					this.$refs[this.values[0].id + "item"][0].classList.add(
						"item-selected"
					);
				}, 1);

				if (this.$route.query.id) {
					setTimeout(() => {
						this.changeTheText(this.$route.query.id);
					}, 1);
				}
			}
		}, 1);
		// this.$store
		// 	.dispatch("values/fetchAll")
		// 	.then()
		// 	.finally(() => {
		// });
	},
};
</script>

<style scoped>
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div {
	padding: 0 60px;
}
.title {
	width: 100%;
	position: relative;
}
.title img {
	width: 100%;
	height: 280px;
	object-fit: cover;
	filter: contrast(70%);
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 60px !important;
	border: 1px solid #ccc;
}
.top,
.bottom {
	display: flex;
}
.head {
	padding: 60px 0;
	border-top: 5px solid #474e56;
	background: #d8dde1;
	max-width: 140px;
	min-width: 140px;
}
.h-top {
	font-size: 30px;
	text-align: center;
	font-family: RobotoBold;
}
.h-mieyar {
	color: var(--first-color);
	font-size: 38px;
	text-align: center;
	font-family: RobotoBlack;
}
.para {
	padding: 40px 0 0 40px;
}
.pre-para {
	padding: 0;
	padding-left: 40px;
	white-space: pre-line;
	opacity: 0;
	transform: translateY(6px);
}
.pre-para-fade-in {
	animation: pre-para-fade-in 0.4s ease-in-out forwards;
}
@keyframes pre-para-fade-in {
	from {
		opacity: 0;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}
.menu {
	width: 140px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	margin-bottom: 30px;
}
.menu-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #474e56;
	padding: 10px 7px;
	width: 100%;
	cursor: pointer;
}
.menu-img {
	width: 30px;
	display: block;
	margin-bottom: 5px;
}
.menu-text {
	line-height: 30px;
	text-align: center;
	color: white;
	font-family: Roboto;
}
.item-selected {
	background: #3b4046;
}
.menu-img {
	filter: brightness(100);
}
.item-selected .menu-img {
	filter: none;
}
.item-selected .menu-text {
	color: var(--first-color);
}
.pp-title {
	font-size: 2rem;
	color: var(--first-color);
	padding: 10px 0;
	font-family: RobotoBlack;
}
@media only screen and (max-width: 992px) {
	.container > div {
		padding: 20px 20px 40px;
	}
	.breadcrumbs {
		padding: 10px 20px !important;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
	.top,
	.bottom {
		flex-direction: column;
	}
	.top {
		padding-bottom: 0 !important;
		padding-top: 0 !important;
	}
	.bottom {
		padding-top: 0 !important;
	}
	.head {
		width: 100%;
		max-width: unset;
		min-width: unset;
		padding: 17px 0 27px;
	}
	.h-top {
		font-size: 2rem;
	}
	.h-mieyar {
		font-size: 3rem;
	}
	.para {
		padding: 20px 0;
	}
	.menu-container {
		position: relative;
		width: 90vw;
		margin: auto;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.menu {
		position: absolute;
		max-width: unset;
		min-width: unset;
		width: unset;
		flex-direction: row;
		left: 0;
		transition: 300ms;
	}
	.menu-container,
	.menu {
		height: 100px;
	}
	.menu-item {
		width: 25vw;
		height: 100%;
	}
	.menu-text {
		line-height: 20px;
	}
	.pre-para {
		padding: 0;
	}
	.arrow-container {
		position: absolute;
		top: 0;
		width: 30px;
		height: 100%;
		z-index: 20;
	}
	.menu-left {
		left: 0;
	}
	.menu-right {
		right: 0;
	}
	.arrow {
		width: 10px;
		height: 10px;
		border-bottom: 1px solid white;
		border-right: 1px solid white;
		position: absolute;
		top: 45%;
		z-index: 21;
	}
	.left-arrow {
		transform: rotate(135deg);
		left: 7px;
	}
	.right-arrow {
		transform: rotate(-45deg);
		right: 7px;
	}
}
@media (max-width: 576px) {
	.menu-item {
		width: 34vw;
	}
}
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	.head,
	.menu {
		max-width: 180px;
		min-width: 140px;
	}
}
.title .head-image,
.title .loading-cover {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
</style>
<style >
.paragraph-about * {
	font-family: Roboto !important;
	width: auto !important;
}
</style>