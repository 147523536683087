<template>
	<router-link :to="to" style="text-decoration: none">
		<div class="f-card" :class="{ header: cardtype === 'header' }">
			<div class="f-card-name">
				{{ item.name }}
			</div>
			<div class="f-card-stand">
				{{
					item.summary?.length > 60
						? item.summary.slice(0, 60) + ".."
						: item.summary
				}}
			</div>
			<div class="f-card-bottom">
				<span class="read-more">read more</span>
				<i
					class="fas fa-angle-double-right"
					style="color: var(--first-color)"
				></i>
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	props: {
		item: { default: {} },
		cardtype: { default: null },
		to: { default: {} },
	},
};
</script>

<style>
.f-card-bottom {
	display: flex;
	align-items: center;
}
.read-more {
	color: var(--first-color);
	margin-inline-end: 5px;
	overflow: hidden;
	max-width: 0;
	display: inline-block;
	transition: 0.2s;
	white-space: nowrap;
	font-weight: bold;
}
.f-card:hover .read-more {
	transition-delay: 0.3s;
	max-width: 100px;
}
@media (hover: none) {
	.f-card .read-more {
		max-width: 100px;
	}
}
.f-card {
	padding: 8px 0 8px 20px;
	border-radius: 2px;
	border-left: 3px solid var(--color-white);
	position: relative;
	transition: 200ms;
}
.f-card:hover {
	scale: 1.05;
	background: #63a6bd82;
	/* color: white; */
	border-left: 3px solid var(--first-color);
	cursor: pointer;
}
.f-card svg {
	position: absolute;
	display: none;
	top: 60%;
	right: 5%;
	height: 12px;
}
.f-card:hover svg {
	display: block;
}
.f-card-name {
	font-size: 1.1rem;
	line-height: 1.6rem;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 270px;
	font-family: RobotoBlack;
	color: var(--font-color);
}
.f-card-stand {
	color: #778994;
}

.header {
	padding: 5px;
	border-left: 3px solid white;
}
.header .f-card-name {
	font-size: 1.05rem;
	line-height: 1.3rem;
	/* white-space: nowrap; */
	overflow: hidden;
	text-overflow: ellipsis;
	color: #525a64;
	font-family: Roboto;
}
.header .f-card-stand {
	font-size: 0.9rem;
	color: #778994;
}
.f-card-bottom * {
	font-size: 0.9rem;
}
</style>
