<template>
	<div class="container px-0 mx-0">
		<div class="title" style="padding: 0">
			<img src="../../assets/Images/1.jpg" alt="" />
			<div class="t-text">
				<div class="t-mid">Services</div>
				<div class="line"></div>
				<div class="t-down">
					We offer the full spectrum of services to help organizations work
					better.
				</div>
			</div>
			<loader line v-if="loading" />
		</div>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'Services', path: '/all-services', active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" />
		<div v-else class="main">
			<div class="content-title">Mieyar's Services</div>
			<p class="paragraph">
				We offer the full spectrum of services to help organizations work
				better. Everything from creating standards of excellence to training
				your people to work in more effective ways, assessing how you’re doing,
				and helping you perform even better in future. Very few others do this,
				and none have been doing it as long as we have.
			</p>
			<p class="paragraph">
				We can combine any of our products and services to create a package
				that’s tailored to your business. This removes the complexity and
				unnecessary cost of getting you where you want to be – whatever your
				starting point.
			</p>
			<div class="contain row mx-0">
				<service-card
					v-for="service in services"
					:key="service.id"
					:text="service.Title"
					:image="service.primary_image"
					:to="{ name: 'service', params: { id: service.id } }"
					class="col-lg-3 col-md-4 col-sm-6 col-12"
				>
				</service-card>
			</div>
		</div>
	</div>
</template>

<script>
import ServiceCard from "../comp/ServiceCard.vue";
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import Loader from "../Loader";
import { mapState } from "vuex";
export default {
	components: { ServiceCard, Breadcrumbs, Loader },

	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			services: (state) => state.services.services,
		}),
	},

	mounted() {
		if (!this.services.length)
			this.$store.dispatch("services/fetchAll").finally(() => {
				this.loading = false;
			});
		else {
			this.loading = false;
		}
	},
};
</script>

<style scoped>
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div {
	padding: 0 80px 50px;
}
.title {
	width: 100%;
	position: relative;
}
.title img {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}
@media only screen and (max-width: 992px) {
	.container > div {
		padding: 20px 10px 40px;
	}
	.breadcrumbs {
		padding: 10px 20px !important;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
}
.main {
	padding-top: 50px !important;
}
.content-title {
	font-size: 3rem;
	color: var(--first-color);
	font-family: RobotoBold;
}
.paragraph {
	font-size: 1.1rem;
}
</style>
