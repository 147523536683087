<template>
	<div class="container px-0 mx-0">
		<div class="title" style="padding: 0">
			<img src="../../assets/Images/1.jpg" alt="" />
			<div class="t-text">
				<div class="t-mid">News</div>
				<div class="line"></div>
				<div class="t-down">
					At Mieyar, our mission is to share knowledge, innovation and best
					practice.
				</div>
			</div>
			<loader line v-if="loading" />
		</div>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'News', path: '/all-news', active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" />
		<div class="main" v-else>
			<div class="contain row mx-0">
				<div class="col-12 contant-title" style="height: auto">all news</div>
				<div
					class="col-lg-4 col-md-6 col-sm-12 mb-4"
					v-for="(news, i) in news.slice(0, lastNews)"
					:key="news.id"
				>
					<news-card :first="!(i % 4)" :data="news"></news-card>
				</div>
				<div class="col-12">
					<div class="my-btn" @click="moreNews()" v-if="thereIsMoreNews">
						more news
					</div>
				</div>
			</div>
			<div class="top-standards">
				<h2 class="contant-title">standards</h2>
				<div v-for="standard in standards.slice(0, 4)" :key="standard.id">
					<item-card
						:item="standard"
						:to="{ name: 'standard', params: { id: standard.id } }"
					></item-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NewsCard from "../comp/NewsCard.vue";
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import Loader from "../Loader";
import { mapState } from "vuex";
import ItemCard from "../comp/ItemCard.vue";

export default {
	components: { NewsCard, Breadcrumbs, Loader, ItemCard },

	data() {
		return {
			loading: true,
			rowCount: 2,
			mobile: false,
		};
	},
	computed: {
		lastNews() {
			return this.mobile ? 2 * this.rowCount : 3 * this.rowCount;
		},
		thereIsMoreNews() {
			return this.mobile
				? this.news.length > 2 * this.rowCount
				: this.news.length > 3 * this.rowCount;
		},
		...mapState({
			news: (state) => state.news.news,
			standards: (state) => state.standards.standards,
		}),
	},
	methods: {
		moreNews() {
			this.rowCount += 2;
		},
	},
	mounted() {
		if (!this.news.length)
			this.$store.dispatch("news/fetchAll").finally(() => {
				this.loading = false;
			});
		else this.loading = false;
		if (window.innerWidth < 1200) this.mobile = true;
		else this.mobile = false;
		window.addEventListener("resize", () => {
			if (window.innerWidth < 1200) this.mobile = true;
			else this.mobile = false;
		});
	},
};
</script>

<style scoped>
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div {
	padding: 0 80px 50px;
}
.title {
	width: 100%;
	position: relative;
}
.title img {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}
.main {
	padding-top: 50px !important;
	display: flex;
}
@media (max-width: 768px) {
	.main {
		flex-direction: column;
	}
}
.contain {
	padding-right: 15px;
}
.contain > div {
	height: 400px;
}
@media (max-width: 1200px) and (min-width: 600px) {
	.contain > div:not(:first-child) {
		width: 50%;
	}
}
@media (max-width: 992px) {
	.contain > div {
		height: 300px;
	}
}
@media (max-width: 600px) {
	.contain > div {
		height: auto;
	}
}
.top-standards {
	position: sticky;
	top: 120px;
	min-width: 250px;
	padding-left: 15px;
	border-left: 1px solid var(--first-color);
	height: max-content;
}
@media (min-width: 1500px) {
	.top-standards {
		min-width: 350px;
	}
}
.contant-title {
	font-size: 2.2rem;
	color: var(--first-color);
	font-family: RobotoBold;
	text-transform: capitalize;
	margin-bottom: 0.4rem;
}
@media (max-width: 768px) {
	.top-standards {
		display: grid;
		grid-template-columns: 1fr 1fr;
		border: none;
		padding: 0;
		row-gap: 10px;
		column-gap: 20px;
	}
	.top-standards > *:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}
@media only screen and (max-width: 992px) {
	.container > div {
		padding: 20px 10px 40px;
	}
	.breadcrumbs {
		padding: 10px 20px !important;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
}
.contain > div:last-child {
	height: auto !important;
	width: 100% !important;
}
.my-btn {
	width: max-content;
	margin: auto;
	border-radius: 7px;
	padding: 10px 25px;
	background: var(--first-color);
	color: white;
	font-family: RobotoBold;
	cursor: pointer;
}
</style>
