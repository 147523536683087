import * as types from '../mutations-types'

export default {
    namespaced: true,
    
    state:{
        images: []
    },

    actions:{
        fetchAll({ commit }){
            return axios.get('/sliders')
            .then((response) => {
                commit(types.STORE_SLIDER_IMAGES, response.data);
                return response.data;
            })
        }
    },

    mutations:{
        [types.STORE_SLIDER_IMAGES](state, images){
            state.images = images;
        }
    }
}