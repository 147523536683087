<template>
	<div class="slider" v-if="!loading">
		<div
			id="carouselExampleIndicators"
			class="carousel slide"
			data-bs-ride="carousel"
			data-bs-interval="true"
		>
			<div class="carousel-indicators" ref="buttons">
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					:data-bs-slide-to="index"
					:aria-label="'Slide' + (index + 1)"
					v-for="(testimonial, index) in testimonials.slice(0, 10)"
					:key="testimonial.id"
				></button>
			</div>
			<div class="carousel-inner" ref="inner">
				<div
					class="carousel-item"
					data-bs-interval="4000"
					v-for="testimonial in testimonials"
					:key="testimonial.id"
				>
					<div class="carousel-item-contant">
						<div class="image">
							<img :src="testimonial.primary_image" />
						</div>
						<div class="t-para">
							<img src="../../assets/Icons/Quote.png" alt="" />
							<span
								class="t-para-description"
								v-html="testimonial.description"
							></span>
							<div class="t-name">{{ testimonial.title }}</div>
						</div>
					</div>
				</div>
			</div>
			<button
				class="carousel-control-prev btns"
				type="button"
				data-bs-target="#carouselExampleIndicators"
				data-bs-slide="prev"
			>
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Previous</span>
			</button>
			<button
				class="carousel-control-next btns"
				type="button"
				data-bs-target="#carouselExampleIndicators"
				data-bs-slide="next"
			>
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Next</span>
			</button>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	data() {
		return {
			loading: true,
		};
	},
	methods: {
		fetch() {
			if (!this.testimonials.length) {
				this.$store
					.dispatch("testimonials/fetchAll")
					.then(() => {
						this.loading = false;
					})
					.finally(() => {
						this.initSlider();
					});
			} else {
				this.loading = false;
				setTimeout(() => {
					this.initSlider();
				}, 1);
			}
		},
		initSlider() {
			this.$refs.inner.childNodes[1].classList.add("active");
			this.$refs.buttons.childNodes[1].classList.add("active");
		},
	},
	computed: {
		...mapState({
			testimonials: (state) => state.testimonials.testimonials,
		}),
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style scoped>
.carousel-item {
	margin-top: 30px;
}
.carousel-item-contant {
	display: flex;
	align-items: center;
}
.carousel-item-contant > .image {
	height: 180px;
	min-width: 180px;
	max-width: 180px;
	margin-right: 50px;
	position: relative;
	padding: 10px;
}
.carousel-item-contant > .image > img {
	height: 100%;
	width: 100%;
	border-radius: 50%;
	position: relative;
	object-fit: cover;
}
.t-para {
	position: relative;
	z-index: 1;
	padding: 0 20px;
}
.t-para > img {
	position: absolute;
	top: -13px;
	left: -2px;
	opacity: 0.7;
	width: 22px;
	z-index: -1;
}
.carousel-item-contant > .image::before {
	content: "";
	position: absolute;
	width: calc(100% - 12px);
	height: calc(100% - 12px);
	top: 3px;
	left: 3px;
	border-radius: 50%;
	background: var(--third-color);
}
.t-name {
	font-family: RobotoItalic;
	color: #c5c5c5;
}
@media (max-width: 768px) {
	.t-para-description {
		text-align: justify;
	}
	.t-name {
		text-align: right;
	}
}
.btns {
	display: none;
}
.carousel-indicators {
	bottom: -30px;
}
.carousel-indicators button {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	padding: 0;
	border: none;
}
.carousel-indicators button.active {
	background: var(--first-color);
}
.carousel-inner {
	width: 90%;
	margin: auto;
}
@media (max-width: 768px) {
	.carousel-inner {
		width: 100%;
	}
}
@media (max-width: 768px) {
	.carousel-item-contant {
		flex-direction: column;
	}
	.image {
		margin: 0 !important;
		margin-bottom: 40px !important;
	}
}
</style>
<style>
.t-para-description {
	font-size: 1.4rem;
	font-family: RobotoBold;
	margin-bottom: 0;
	display: block;
	color: white;
	height: 5.6rem;
	line-height: 1.4rem;
	overflow: hidden;
}
</style>