import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        standards: [],
        services: [],
        news: [],
    },

    actions: {
        search({ commit }, search) {
            return axios.get('standards/search', { params: { text: search } })
                .then((response) => {
                    commit(types.STORE_SEARCH, response.data);
                    return response.data;
                })
        },
    },

    mutations: {
        [types.STORE_SEARCH](state, data) {
            state.standards = data.Standards
            state.news = data.News
            state.services = data.Services
        }
    },
}