<template>
	<div class="container px-0 mx-0">
		<div class="title">
			<div v-if="loading" class="loading-cover" />
			<img v-else-if="sector.image" class="head-image" :src="sector.image" />
			<img v-else class="head-image" src="../../assets/Images/1.jpg" />
			<div class="t-text">
				<h1 class="t-mid">{{ sector.name }}</h1>
				<div class="line" v-if="!loading"></div>
			</div>
		</div>

		<loader line v-if="loading" />

		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'Sectors', path: '/all-sectors' },
					{ name: sector.name, path: '/', active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" />
		<div v-else class="row main mx-0">
			<div class="s-title col-12">Available standards</div>
			<div
				class="column col-lg-3 col-md-4 col-6"
				v-for="standard in sector.standards"
				:key="standard.id"
			>
				<item-card
					:item="standard"
					:to="{ name: 'standard', params: { id: standard.id } }"
				></item-card>
			</div>
			<div v-if="sector.standards.length === 0" class="fs-4 fw-lighter">
				No standards for this sector
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import ItemCard from "../comp/ItemCard.vue";
import Loader from "../Loader";

export default {
	data() {
		return {
			loading: true,

			sector: { standards: [] },
		};
	},

	watch: {
		$route(newRoute) {
			if (newRoute.params.id) {
				this.fetchSector();
			}
		},
	},

	components: {
		Breadcrumbs,
		ItemCard,
		Loader,
	},

	methods: {
		fetchSector() {
			this.loading = true;
			return this.$store
				.dispatch("sectors/fetchById", { id: this.$route.params.id })
				.then((data) => {
					this.sector = data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	mounted() {
		this.fetchSector();
	},
};
</script>

<style scoped>
* {
	color: var(--font-color);
}
.container {
	max-width: 100% !important;
	min-height: 80vh;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div.main {
	padding: 0 80px 50px;
}
.title {
	width: 100%;
	position: relative;
}
.title .head-image,
.title .loading-cover {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.title .loading-cover {
	background-color: var(--sec-color);
	filter: grayscale(0.6);
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}

.s-title {
	font-size: 32px;
	color: var(--first-color);
	font-family: RobotoBlack;
	margin: 20px 0;
	text-transform: uppercase;
}
.main {
	padding: 0 60px !important;
	padding-bottom: 30px !important;
}
.column {
	padding: 10px;
}

@media only screen and (max-width: 992px) {
	.container > div.main {
		padding: 20px 25px 40px;
		margin: 0;
	}
	.s-title {
		padding: 0 !important;
		font-size: 1.8rem;
	}
	.main {
		padding: 0 25px !important;
	}
	.column {
		padding: 10px 0;
	}
	.breadcrumbs {
		padding: 10px 25px !important;
	}
	.t-text {
		width: 70%;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
}
</style>