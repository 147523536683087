<template>
	<div class="all">
		<div class="top-nav" ref="top">
			<router-link class="top-nav-link me-auto" :to="{ name: 'contact-us' }">{{
				this.contacts.find((e) => e.Key === "phone")?.Value
			}}</router-link>

			<router-link class="top-nav-link" :to="{ name: 'contact-us' }"
				>Contact us</router-link
			>
		</div>

		<div class="container" ref="header">
			<nav class="navbar navbar-expand-lg">
				<div class="container-fluid px-0">
					<router-link class="navbar-brand" :to="{ name: 'home' }">
						<img src="..\assets\CompanyLogo\logo.png" alt="" />
					</router-link>
					<button
						class="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#drawer"
						aria-controls="drawer"
						aria-expanded="false"
						aria-label="Toggle navigation"
						ref="nav-button"
					>
						<span class="navbar-toggler-icon drawer-icon"
							><i class="fas fa-bars" style="color: white"></i
						></span>
					</button>

					<div class="collapse navbar-collapse drawer" id="drawer" ref="drawer">
						<ul class="navbar-nav ms-auto">
							<li class="nav-item">
								<router-link :to="{ path: '/' }" style="text-decoration: none"
									>Home</router-link
								>
							</li>
							<li
								class="nav-item dropdown"
								@mouseenter="dropdownId = 'dropdown1'"
								@mouseleave="dropdownId = null"
							>
								<a
									@click.self="
										dropdownId == 'dropdown1'
											? (dropdownId = null)
											: (dropdownId = 'dropdown1');
										myHref({ name: 'all-standards' });
									"
									@tap="logasd()"
									class="nav-link dropdown-toggle"
									id="dropdown1"
									role="button"
									:class="{ show: dropdownId == 'dropdown1' }"
								>
									Standards
									<img src="../assets/Icons/arrow-down.png" alt="" />
								</a>
								<div
									class="dropdown-menu dd"
									:class="{ show: dropdownId == 'dropdown1' }"
								>
									<div class="dd-search">
										<i class="fas fa-search" style="color: #ccc"></i>
										<form @submit.prevent="search('standard')">
											<input
												type="text"
												placeholder="Search..."
												v-model="searchText"
											/>
										</form>
									</div>
									<item-card
										v-for="standard in standards.slice(0, 9)"
										:key="standard.id"
										:item="standard"
										:to="{ name: 'standard', params: { id: standard.id } }"
										:cardtype="'header'"
									></item-card>

									<div class="dd-see-all">
										<router-link
											:to="{ name: 'all-standards', hash: '#list' }"
											style="text-decoration: none"
										>
											See All Standards
										</router-link>
										<i
											class="fas fa-angle-double-right"
											style="color: var(--first-color)"
										></i>
									</div>
								</div>
							</li>
							<li class="nav-item">
								<router-link
									:to="{ name: 'all-services' }"
									style="text-decoration: none"
									>Services</router-link
								>
							</li>
							<li
								class="nav-item dropdown"
								@mouseenter="dropdownId = 'dropdown2'"
								@mouseleave="dropdownId = null"
							>
								<a
									@click.self="
										dropdownId == 'dropdown2'
											? (dropdownId = null)
											: (dropdownId = 'dropdown2');
										myHref({ name: 'all-sectors' });
									"
									class="nav-link dropdown-toggle"
									id="dropdown2"
									role="button"
									:class="{ show: dropdownId == 'dropdown2' }"
								>
									Sectors
									<img src="../assets/Icons/arrow-down.png" alt="" />
								</a>
								<div
									class="dropdown-menu dd dd-sectors"
									:class="{ show: dropdownId == 'dropdown2' }"
								>
									<item-card
										v-for="sector in sectors"
										:key="sector.id"
										:item="sector"
										:to="{ name: 'sector', params: { id: sector.id } }"
										:cardtype="'header'"
										class="pb-3"
									></item-card>

									<div class="dd-see-all">
										<router-link
											:to="{ path: 'all-sectors' }"
											style="text-decoration: none"
										>
											See All Sectors
										</router-link>
										<i
											class="fas fa-angle-double-right"
											style="color: var(--first-color)"
										></i>
									</div>
								</div>
							</li>
							<li class="nav-item">
								<router-link
									:to="{ name: 'all-news' }"
									style="text-decoration: none"
									>News</router-link
								>
							</li>
							<li
								class="nav-item dropdown"
								@mouseenter="dropdownId = 'dropdown3'"
								@mouseleave="dropdownId = null"
							>
								<a
									@click="
										dropdownId == 'dropdown3'
											? (dropdownId = null)
											: (dropdownId = 'dropdown3')
									"
									class="nav-link dropdown-toggle"
									id="dropdown3"
									role="button"
									:class="{ show: dropdownId == 'dropdown3' }"
								>
									About
									<img src="../assets/Icons/arrow-down.png" alt="" />
								</a>
								<ul
									class="dropdown-menu dd dd-about"
									:class="{ show: dropdownId == 'dropdown3' }"
								>
									<router-link
										v-for="value in values"
										:key="value.id"
										:to="{ name: 'about-us', query: { id: value.id } }"
										style="text-decoration: none"
										><li>{{ value.Title }}</li></router-link
									>
								</ul>
								<!-- <ul
									class="dropdown-menu dd dd-about"
									:class="{ show: dropdownId == 'dropdown3' }"
								>
									<router-link
										:to="{ name: 'about-us', query: { id: 0 } }"
										style="text-decoration: none"
										><li>Our People</li></router-link
									>
									<router-link
										:to="{ name: 'about-us', query: { id: 1 } }"
										style="text-decoration: none"
										><li>Our Client</li></router-link
									>
									<router-link
										:to="{ name: 'about-us', query: { id: 2 } }"
										style="text-decoration: none"
										><li>Our Partner</li></router-link
									>
									<router-link
										:to="{ name: 'about-us', query: { id: 3 } }"
										style="text-decoration: none"
										><li>Our Members</li></router-link
									>
									<router-link
										:to="{ name: 'about-us', query: { id: 4 } }"
										style="text-decoration: none"
										><li>Governance</li></router-link
									>
									<router-link
										:to="{ name: 'about-us', query: { id: 5 } }"
										style="text-decoration: none"
										><li>Social Responsibility</li></router-link
									>
									<router-link
										:to="{ name: 'about-us', query: { id: 6 } }"
										style="text-decoration: none"
										><li>Our History</li></router-link
									>
								</ul> -->
							</li>
							<li class="nav-item search-btn">
								<a>
									<i
										:class="showSearch ? 'fa fa-times' : 'fa fa-search'"
										style="color: white"
										@click="showSearch = !showSearch"
									></i>
									<div
										class="dropdown search-input"
										:class="{ show: showSearch }"
									>
										<form @submit.prevent="search('all')">
											<input
												type="text"
												placeholder="Search..."
												v-model="searchText"
											/>
										</form>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	</div>
</template>

<script>
import ItemCard from "../components/comp/ItemCard.vue";
import { mapState } from "vuex";

export default {
	components: {
		ItemCard,
	},

	data() {
		return {
			windowWidth: null,
			windowHeight: null,
			dropdownId: null,
			showSearch: false,
			searchText: null,
		};
	},

	computed: {
		isMobile() {
			return this.windowWidth > 992 ? false : true;
		},
		...mapState({
			standards: (state) => state.standards.standards,
			sectors: (state) => state.sectors.sectors,
			contacts: (state) => state.contactUs.contacts,
			values: (state) => state.values.values,
		}),
	},
	methods: {
		myHref(link) {
			if (window.innerWidth > 992) {
				this.$router.push(link);
			}
		},
		search(type) {
			switch (type) {
				case "all":
					this.$router.push({
						name: "search",
						query: { text: this.searchText },
					});
					break;
				case "standard":
					this.$router.push({
						name: "search",
						query: { text: this.searchText, type: type },
					});
					break;

				default:
					break;
			}
		},
	},
	watch: {
		$route() {
			this.dropdownId = null;
			this.$refs["nav-button"].classList.add("collapsed");
			this.$refs.drawer.classList.remove("show");
			this.showSearch = false;
		},
	},
	mounted() {
		this.$store.dispatch("standards/fetchAll");
		this.$store.dispatch("sectors/fetchAll");
		this.$store.dispatch("values/fetchAll");
		this.$store.dispatch("contactUs/fetchAll");
		window.addEventListener("scroll", (e) => {
			if (document.documentElement.scrollTop > this.$refs.top.clientHeight) {
				this.$refs.header.classList.add("scrolled");
			} else {
				this.$refs.header.classList.remove("scrolled");
			}
		});
	},
};
</script>

<style scoped>
.container {
	transition: 500ms;
	background: linear-gradient(to bottom, #0004 20%, transparent);
}
.container.scrolled {
	position: fixed;
	background: #000a;
	top: 0;
}
.all {
	position: absolute;
	width: 100%;
	z-index: 10;
}
.top-nav {
	display: flex;
	justify-content: flex-end;
	padding: 8px 80px;
	border-bottom: 2px solid var(--third-color);
	background: #001d3e;
}
@media (max-width: 992px) {
	.top-nav {
		padding: 8px 20px;
	}
}
@media (min-width: 1500px) {
	.top-nav {
		padding: 8px 150px;
	}
}
.top-nav-link {
	display: inline-block;
	font-size: 0.8rem;
	text-decoration: none;
}
.top-nav-link:nth-of-type(2) {
	margin-left: 1.4rem;
}
.top-nav * {
	color: white;
}
.container {
	padding: 0 80px;
	margin: 0;
	max-width: 100%;
	color: white;
}
@media (max-width: 1200px) {
	.container {
		padding: 0 20px;
	}
}
.navbar {
	padding: 10px 0;
}
.navbar-brand {
	height: 65px;
	position: relative;
}
.navbar-brand img {
	height: 100%;
}
.navbar a {
	padding: 0;
	color: inherit;
}
.navbar-nav {
	padding: 0;
}
.nav-item {
	/* padding: 20px 0; */
	margin-left: 25px;
	font-size: 1.1rem;
	font-weight: 600;
	color: white;
	border-bottom: 3px solid #0000;
}
.nav-item > a {
	padding: 20px 0;
	display: block;
}
@media (min-width: 992px) {
	.nav-item:not(:last-child):hover {
		border-bottom: 3px solid var(--first-color);
		color: var(--first-color);
	}
}
.dropdown-toggle::after {
	display: none;
}
.dropdown-toggle img {
	margin-left: 5px;
	height: 10px;
	transition: 500ms;
}
.show > img {
	transform: rotateX(180deg);
}
div.show.dd {
	display: grid !important;
}
.dd {
	display: none;
	grid-row-gap: 10px;
	grid-column-gap: 20px;
	grid-template-columns: repeat(3, calc(33.3333% - 14px));
	padding: 15px 30px;
	width: 800px;
	right: -450px;
	top: 68px;
}
.dd-sectors {
	right: -210px;
}
.dd-search {
	grid-column-start: 1;
	grid-column-end: 4;
	padding: 0 5px;
	border: 1px solid #333;
	border-radius: 3px;
}
.dd-search svg {
	padding: 2px;
	margin-right: 10px;
}
.dd-search form {
	width: 90%;
	display: inline-block;
	color: #333;
}
.dd-search input {
	border: none;
	outline: none;
	width: 100%;
}
.dd-see-all {
	grid-column-start: 1;
	grid-column-end: 4;
	font-size: 0.9rem;
	color: var(--first-color);
	margin-top: 5px;
}
.dd-see-all svg {
	margin-left: 5px;
	height: 10px;
}
.dd-see-all a:hover {
	text-decoration: underline !important;
}
.dd-about.show {
	display: grid;
}
.dd-about {
	padding: 10px 20px;
	right: -5px;
	left: unset;
	min-width: 170px !important;
}
.dd-about li {
	padding: 8px 0 2px;
	margin: 0;
	border-bottom: 2px solid transparent;
	color: #525a64;
	width: max-content;
}
.dd-about li:hover {
	color: var(--first-color);
	border-bottom: 2px solid var(--first-color);
}
@media only screen and (max-width: 992px) {
	.drawer {
		background: #0009;
		border-radius: 10px;
		max-height: 83vh;
		overflow-y: auto;
	}
	.navbar-toggler-icon svg {
		width: 100%;
		height: 100%;
	}
	.navbar-toggler:focus {
		box-shadow: none;
	}
	.navbar-toggler-icon,
	.navbar-brand {
		height: 50px;
	}
	.drawer-icon {
		display: flex;
		align-items: center;
	}
	.drawer-icon > i {
		font-size: 2rem;
	}
	.nav-item {
		margin-right: 25px;
	}
	.dd {
		padding: 15px 10px;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 5px;
		grid-column-gap: 10px;
		width: 100%;
	}
	.dd-search,
	.dd-see-all {
		grid-column-end: 3;
	}
}
@media (max-width: 576px) {
	.dd-search,
	.dd-see-all {
		grid-column-end: 2;
	}
	.dd {
		grid-template-columns: 1fr;
	}
}
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	.navbar-brand img {
		height: 110%;
	}
}
@media only screen and (min-width: 1500px) {
	.nav-item {
		font-size: 1.1rem;
	}
}
.search-btn {
	position: relative;
}
.search-btn a {
	display: flex;
	flex-direction: row-reverse;
}
.search-btn i {
	margin-top: 5px;
}
.search-input {
	border-radius: 3px;
	max-width: 0;
	overflow: hidden;
	transition: 300ms linear;
}
.search-input.show {
	max-width: 250px;
	margin-right: 7px;
}
@media (max-width: 992px) {
	.search-btn a {
		flex-direction: row;
		align-items: flex-start;
	}
	.search-input {
		width: 100%;
	}
	.search-input.show {
		margin-right: 0;
		margin-left: 7px;
	}
	.search-input input {
		width: 100%;
	}
}
</style>
