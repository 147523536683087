<template>
	<div class="mb-3">
		<div class="s-card">
			<div class="s-card-img">
				<router-link :to="to">
					<img :src="image" />
				</router-link>
			</div>
			<div class="s-card-bottom">
				<div class="s-card-p">{{ text }}</div>
				<div class="s-card-btn">
					<my-btn :to="to" text="Read More"></my-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MyBtn from "./MyBtn.vue";
export default {
	components: {
		MyBtn,
	},
	name: "ServiceCard",
	props: {
		id: { default: null },
		text: { default: null },
		image: { default: null },
		to: { default: {} },
	},
};
</script>

<style scoped>
.s-card {
	max-width: 300px;
	margin: auto;
	transition: transform 0.1s;
	position: relative;
	height: 400px;
	z-index: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	border-radius: 4px;
}
@media (max-width: 992px) {
	.s-card {
		height: 350px;
	}
}
.s-card .s-card-img {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: -1;
	transition: 0.3s;
}
.s-card .s-card-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.9s;
}
.s-card-bottom {
	width: 100%;
	background: linear-gradient(to top, #082d42ab, transparent);
	padding-top: 40px;
}
.s-card-p {
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 7px;
	font-family: RobotoBold;
	text-align: center;
	color: white;
}
.s-card-btn {
	width: max-content;
	overflow: hidden;
	max-height: 0;
	transition: 200ms;
	margin: auto;
	margin-bottom: 0;
}
.s-card:hover .s-card-btn {
	max-height: 50px;
	margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
	.s-card {
		margin-bottom: 20px;
	}
	.s-card-p {
		font-size: 16px;
		line-height: 20px;
	}
}

@media only screen and (max-width: 575px) {
	.s-card {
		text-align: center;
	}
	.s-card-btn {
		margin: 0 auto;
	}
}
/* .s-card:hover .s-card-img {
	box-shadow: 0 0 3px#333;
} */
/* .s-card:hover .s-card-img img {
	transform: scale(1.2);
	transition: 0.4s;
} */
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	.s-card-p {
		font-size: 18px;
	}
	.s-card-p {
		margin-bottom: 16px;
	}
}
</style>
