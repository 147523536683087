<template>
	<div class="container px-0 mx-0">
		<div class="title" style="padding: 0">
			<img src="../../assets/Images/1.jpg" alt="" />
			<div class="t-text">
				<div class="t-mid">Sectors</div>
				<div class="line"></div>
				<div class="t-down">
					We can combine any of our products and services to create a package
					that’s tailored to your business.
				</div>
			</div>
		</div>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'Sectors', active: 'active' },
				]"
			></breadcrumbs>
		</div>
		<div class="s-title">Available sectors</div>
		<div class="row main mx-0">
			<div
				class="column col-lg-3 col-12"
				v-for="sector in sectors"
				:key="sector.id"
			>
				<item-card
					:item="sector"
					:to="{ name: 'sector', params: { id: sector.id } }"
					class="pb-4"
				></item-card>
			</div>
		</div>
	</div>
</template>

<script>
import ItemCard from "../comp/ItemCard.vue";
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import { mapState } from "vuex";

export default {
	components: {
		ItemCard,
		Breadcrumbs,
	},

	computed: {
		...mapState({
			sectors: (state) => state.sectors.sectors,
		}),
	},
};
</script>

<style scoped>
.container {
	max-width: 100% !important;
	min-height: 80vh;
	padding-top: 33px;
	background: var(--color-white);
}
.container > div {
	padding: 0 80px;
}
.title {
	width: 100%;
	position: relative;
}
.title img {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}
.s-title {
	font-size: 32px;
	color: var(--first-color);
	font-family: RobotoBlack;
	margin: 20px 0;
	text-transform: uppercase;
}
.main {
	padding: 0 60px !important;
	padding-bottom: 30px !important;
}
.column {
	padding: 10px;
}

@media only screen and (max-width: 992px) {
	.container > div {
		padding: 20px 25px 40px;
		margin: 0;
	}
	.s-title {
		padding-bottom: 0 !important;
	}
	.main {
		padding: 0 25px !important;
	}
	.column {
		padding: 10px 0;
	}
	.breadcrumbs {
		padding: 10px 25px !important;
	}
	.t-text {
		width: 70%;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
}
</style>
