<template>
	<div class="container px-0 mx-0">
		<div class="title" style="padding: 0">
			<div v-if="loading" class="loading-cover" />
			<img v-else class="head-image" :src="service.images[0]" />
			<div class="t-text">
				<h1 class="t-mid">{{ service.Title }}</h1>
				<div class="line" v-if="!loading"></div>
			</div>
			<loader line v-if="loading" />
		</div>
		<div class="breadcrumbs">
			<breadcrumbs
				:links="[
					{ name: 'Home', path: '/' },
					{ name: 'Services', path: '/all-services' },
					{ name: service.Title, active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" />
		<div class="content pt-4 pb-5" v-else>
			<div class="main">
				<div id="service-content" v-html="service.Content"></div>
			</div>
			<div class="right">
				<div class="right-image mt-4">
					<img
						:src="service.images[1]"
						alt=""
						:class="{ 'd-none': !service.images[1] }"
					/>
				</div>
				<div class="right-sticky">
					<div class="r-title">Request a quote</div>
					<p>
						Find out how mush ISO 9001 certification coul cost your business
					</p>
					<div class="d-flex right-btns">
						<my-btn
							:text="'Request a Quote'"
							:to="{ name: 'contact-us', query: { standardId: service.id } }"
							class="btn"
						></my-btn>
						<my-btn
							:text="'Make an enquiry'"
							:to="{ name: 'contact-us', query: { standardId: service.id } }"
							class="btn"
						></my-btn>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import Loader from "../Loader";
import MyBtn from "../comp/MyBtn.vue";
export default {
	components: { Breadcrumbs, Loader, MyBtn },

	data() {
		return {
			service: {},
			loading: true,
		};
	},

	watch: {
		$route(newRoute) {
			if (newRoute.params.id) {
				this.fetchService();
			}
		},
	},

	methods: {
		fetchService() {
			this.loading = true;
			this.$store
				.dispatch("services/fetchById", { id: this.$route.params.id })
				.then((data) => {
					this.service = data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	mounted() {
		this.fetchService();
	},
};
</script>

<style scoped>
.container {
	max-width: 100% !important;
	padding-top: 33px;
	background: var(--color-white);
	min-height: 50vh;
}
.container > div:not(:first-child) {
	padding: 0 80px 50px;
}
.content {
	display: flex;
}
.main {
	flex: 2;
	padding-right: 70px;
}
.title {
	width: 100%;
	position: relative;
}
.title .head-image,
.title .loading-cover {
	width: 100%;
	height: 280px;
	filter: contrast(70%);
	object-fit: cover;
}
.title .loading-cover {
	background-color: var(--sec-color);
	filter: grayscale(0.6);
}
.t-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	text-align: center;
	color: white;
	font-family: RobotoBlack;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}
.t-up {
	font-size: 20px;
	color: inherit;
	font-family: inherit;
}
.t-mid {
	font-size: 40px;
	color: inherit;
	line-height: 35px;
	font-family: inherit;
}
.line {
	width: 70px;
	height: 2px;
	background: var(--first-color);
	margin: 25px 0 15px;
}
.t-down {
	font-size: 16px;
	color: inherit;
	font-family: inherit;
	width: max-content;
}
.breadcrumbs {
	padding: 10px 80px !important;
	border: 1px solid #ccc;
}
.right {
	flex: 1;
}
@media (max-width: 992px) and (min-width: 768px) {
	.right {
		display: flex;
	}
	.right-sticky {
		margin-bottom: auto;
		margin-left: 10px;
	}
}
.right-image {
	width: 100%;
}
.right-image > img {
	width: 100%;
}
.right-sticky {
	position: sticky;
	top: 90px;
	height: max-content;
}
.right * {
	font-family: RobotoBlack;
}
.right .r-title {
	color: var(--first-color);
	font-size: 25px;
	margin: 30px 0 10px;
}
@media (max-width: 400px) {
	.right-btns {
		flex-direction: column;
	}
}
.right p {
	font-size: 1rem;
}
.right .btn {
	width: max-content;
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 8px;
}

@media only screen and (max-width: 992px) {
	.container > div:not(:first-child) {
		padding: 20px 10px 40px;
	}
	.breadcrumbs {
		padding: 10px 20px !important;
	}
	.t-mid {
		font-size: 32px;
		width: max-content;
	}
	.t-down {
		width: auto;
	}
	.content {
		flex-direction: column;
	}
	.main {
		padding: 0;
	}
}
</style>

<style>
#service-content * {
	font-family: Roboto;
	font-size: 1rem;
}
#service-content h1,
#service-content h2,
#service-content h3,
#service-content h4,
#service-content h5,
#service-content h6 {
	font-family: RobotoBlack;
	color: var(--first-color);
	font-size: 34px;
	margin: 20px 0 10px;
}
#service-content ul {
	padding-left: 1rem;
}
#service-content img {
	width: 60%;
}

@media only screen and (max-width: 992px) {
	#service-content {
		padding: 0;
	}
	#service-content > * {
		margin: 10px 0;
	}
	#service-content h1,
	#service-content h2,
	#service-content h3,
	#service-content h4,
	#service-content h5,
	#service-content h6 {
		font-size: 30px;
		line-height: 35px;
		margin-bottom: 20px;
		word-break: break-all;
	}
}
#service-content * {
	width: auto !important;
	background: transparent !important;
}
</style>