import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { API_URL } from './constants'
import AtcBtn from './components/comp/MyBtn';

window.axios = axios.create({
  baseURL: API_URL
})
window.axios.defaults.headers.common['accept-language'] = 'en';

const app = createApp(App);
app.use(store).use(router).mount('#app');
app.component('atc-btn', AtcBtn);
