<template>
	<router-link :to="{ name: 'news', params: { id: data.id } }">
		<div class="my-card" :class="{ 'not-first': !first }">
			<div class="image">
				<img :src="data.primary_image" alt="" />
				<div class="title-sticky title">
					{{ data.Title }}
				</div>
			</div>
			<div class="info">
				<div class="title">
					{{ data.Title }}
				</div>
				<div class="description">
					{{ data.summary }}
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	props: {
		first: {
			type: Boolean,
			default: false,
		},
		data: { default: {} },
	},
	mounted() {},
};
</script>

<style scoped>
.my-card {
	height: 100%;
	margin: auto;
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	text-align: left;
}
.image {
	height: 100%;
	width: 100%;
}

@media (max-width: 576px) {
	.image {
		/* height: 350px; */
	}
}
.image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.info {
	position: absolute;
	top: 100%;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 20px 10px 30px;
	border-radius: 3px;
	z-index: 1;
	transition: 300ms;
	/* transform: translateY(120%); */
	display: flex;
	justify-content: center;
	flex-direction: column;
	background: #082d42ab;
	overflow: hidden;
}
.info * {
	color: white;
}
.my-card:hover .info {
	/* transform: translateY(0); */
	top: 0;
}
.my-card:hover .description {
	transition-delay: 350ms;
	transform: translateY(0);
	opacity: 1;
}
.my-card:hover .title-sticky {
	transform: translateY(-50%);
	opacity: 0;
}
.title {
	font-size: 1.2rem;
	font-family: RobotoBold;
	color: white;
	margin-bottom: 7px;
}
.title-sticky {
	position: absolute;
	bottom: 0;
	padding: 10px;
	padding-top: 30px;
	right: 0;
	left: 0;
	background: linear-gradient(to top, #082d42ab 30%, transparent);
	transition: 200ms;
	transform: translateY(0%);
	margin-bottom: 0;
}
@media (min-width: 1500px) {
	.title {
		font-size: 1.5rem;
	}
}
.description {
	transform: translateY(-10px);
	font-weight: 600;
	font-size: 0.8rem;
	opacity: 0;
	transition: 300ms;
}
@media (max-width: 768px) {
	.info {
		transform: translateY(0);
	}
	.description {
		transform: translateY(0);
		opacity: 1;
	}
}
@media (max-width: 600px) {
	.not-first .image {
		display: none;
	}
	.not-first .info {
		position: relative;
		top: 0;
		background: none;
		text-align: left;
		border-bottom: 1px solid black;
		padding: 20px 0 10px;
		overflow: visible;
	}
	.not-first .info * {
		color: black;
	}
	.not-first .title {
		margin-bottom: 3px;
	}
	.not-first .description {
		color: #333;
	}
}
</style>